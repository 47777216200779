body {
  margin: 0;
  padding: 0;
  color: #666666;
  font-family: $font-family;
}

.app {
  display: flex;
  background-color: #efefef;
  min-height: 100vh;
  flex-direction: column;
  flex-wrap: wrap;
}

.add-container {
  display: flex;
  margin-bottom: 15px;
}

.add-left {
  width: 50%;
  padding-right: 5px;
}

.add-right {
  width: 50%;
  padding-left: 5px;
}

.add-menu {
  margin-bottom: 15px;
}

.add-menu .select-menu {
  width: 100%;
  height: 60px;
  color: #999999;
  font-size: 18px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
}

.form_container {
  margin: 0 30px;
}

.container {
  display: flex;
  flex: 1;
}

.container--table-header {
  display: flex;
  flex: 1;

  padding: 0 15px;
  margin-bottom: 5px;
  color: #999999;
}

.content-center {
  text-align: center;
}

.content-right {
  text-align: right;
}

.content-left {
  text-align: left;
}

.list-container {
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
}

.submit_container {
  padding-top: 20px;
  display: flex;
  justify-content: space-evenly;
}

.submit-container {
  text-align: center;
  padding-top: 20px;
}

.view_content {
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
}

.view-content-container {
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
}
