@import "variables";

$fleetAdvise-primary: #292f36;
$fleetAdvise-color: #e2e9e5;
$fleetAdvise-hovar-color: #f0f4f1;

$primary-color: $fleetAdvise-primary;
$primary-color-light: #e2e9e5;
$primary-color-gradient-start: $fleetAdvise-primary;
$primary-color-gradient-end: #6200ed;
$primary-color-hover: #292f36;
$light-blue: $fleetAdvise-primary;
$primary-button-color: $fleetAdvise-color;
$primary-button-hover-color: $fleetAdvise-hovar-color;
$header-text-color: $fleetAdvise-primary;
$primary-button-color: $fleetAdvise-color;
$primary-button-hover-color: $fleetAdvise-hovar-color;
$secondary-button-text-color: $fleetAdvise-primary;
$recharts-primary-color: #e95832;
$recharts-secondary-color: #e95832;
$secondary-color: $fleetAdvise-color;
$primary-text-color: #292f36;
$segment-theme-background-color: #424c57;
$segment-theme-border: 1px solid #d4d8de;
$segment-theme-color: #f0f2f4;
$text-alignment: left;
$primary-button-focus-color: #d7e4dd;
$primary-button-focus-text-color: $primary-text-color;
$button-link-color: #ce3d17;
$alert-notice-background-color: white;
$alert-notice-border-color: white;
$alert-notice-text-color: #424242;
$dash-stats-item-text-color: #f0f2f4;
$primary-button-text-color: $segment-theme-color;
$payment-radio-checked-color: #ffff;
$button-disabled-color: #f0f2f4;
$button-disabled-background-color: #a8b2bd;
$orange-button-color: $fleetAdvise-primary;
$orange-button-color-hover: $primary-theme-text-color-hover;
$dash-stats-item-span-text-color: #f0f2f4;
$fleet-list-highlight-color: #fef6f4;
$fleet-list-highlight-border-color: #fae0d9;
$file-action-btn-color: $button-link-color;
$file-action-btn-hover-color: $button-link-hover-color;
$file-action-btn-hover-background: $primary-theme-text-color-hover;
$button-alternate-text-color: $fleetAdvise-primary;
$toggle-checkbox-color: #e95832;
$checkbox-background-color: #e95832;
$transfer-vehicle-checkbox-background-color: #e95832;
$checkbox-checked-color: #fff;
$primary-button-text-color: $segment-theme-color;
$notification-close-mark-color: #424242;
$notification-success-background-color: #ecfdf5;
$notification-success-text-color: #424242;
$notification-default-background-color: #ecfdf5;
$notification-default-text-color: #424242;
$notification-danger-background-color: #fef2f2;
$notification-danger-text-color: #424242;
$notification-info-background-color: #f0f0f0;
$notification-warning-background-color: #fffbeb;
$menu-hover-color: #e95832;
$gray: #6e7e91;
$checkbox-border-color: #cfcfcf;
$alert-default-color: #424242;
$alert-default-border-color: #a7f3d0;
$alert-default-background-color: #ecfdf5;
$alert-error-color: #424242;
$alert-error-border-color: #fecaca;
$alert-error-background-color: #fef2f2;
$alert-success-color: #424242;
$alert-success-border-color: #a7f3d0;
$alert-success-background-color: #ecfdf5;
$alert-warning-color: #424242;
$alert-warning-border-color: #fde68a;
$alert-warning-background-color: #fffbeb;
$manufacturer-title-checkbox-color: $checkbox-background-color;
$file-action-btn-color: $button-link-color;
$file-action-btn-hover-color: #f3a18c;
$react-switch-label-default-color: #cfcfcf;
$recommended-label-text-color: $fleetAdvise-primary;
$modal-custom-radio-checked-color: $checkbox-background-color;
$link-color: $button-link-color;
