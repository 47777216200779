@import "variables";

$zubie-primary: rgba(0, 193, 222, 1);
$zubie-primary-hover: rgba(36, 161, 187, 1);

$primary-color: $zubie-primary;
$primary-color-light: #d5f1f8;
$primary-color-hover: $zubie-primary-hover;
$light-blue: $zubie-primary;

$primary-color-gradient-start: $zubie-primary;
$primary-color-gradient-end: $zubie-primary;

$primary-button-color: $zubie-primary;
$primary-button-hover-color: $zubie-primary-hover;
$button-gradient-start-color: $zubie-primary;
$button-gradient-end-color: $zubie-primary;

/* Recharts */
$recharts-primary-color: $zubie-primary;
$recharts-secondary-color: $zubie-primary;
$menu-hover-color: $light-blue;
$button-link-color: $primary-button-color;
$manufacturer-title-checkbox-color: $primary-color;
$secondary-color: #00c1de;
$orange-button-color: $primary-button-color;
$orange-button-color-hover: $primary-button-hover-color;
$recommended-label-text-color: $secondary-color;
$modal-custom-radio-checked-color: $primary-color;
$button-disabled-background-color: $primary-color;
$link-color: $primary-color;
