.change_password_form {
}

.change_password_form__button {
  height: 45px;
  margin-bottom: 0;
  width: 100%;
}

.change_password_form__field {
  width: 100%;
}
