@mixin horizontal-primary-gradient {
  background: $primary-color;
  background: linear-gradient(left, $primary-color-gradient-start, $primary-color-gradient-end);
  background: -webkit-linear-gradient(
    left,
    $primary-color-gradient-start,
    $primary-color-gradient-end
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    left,
    $primary-color-gradient-start,
    $primary-color-gradient-end
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    left,
    $primary-color-gradient-start,
    $primary-color-gradient-end
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    left,
    $primary-color-gradient-start,
    $primary-color-gradient-end
  ); /* Standard syntax */
  color: $primary-text-color;
}

@mixin vertical-primary-gradient {
  background: $primary-color;
  background: linear-gradient($primary-color-gradient-start, $primary-color-gradient-end);
  background: -webkit-linear-gradient(
    $primary-color-gradient-start,
    $primary-color-gradient-end
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    $primary-color-gradient-start,
    $primary-color-gradient-end
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    $primary-color-gradient-start,
    $primary-color-gradient-end
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    $primary-color-gradient-start,
    $primary-color-gradient-end
  ); /* Standard syntax */
  color: $primary-text-color;
}
