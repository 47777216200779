.vehicle_details {
  .vehicle_details__form {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-gap: 10px;
    max-width: 1200px;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(200px, 1fr));
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: repeat(1, minmax(100px, 1fr));
    }
  }

  .vehicle-save {
    margin-top: 15px;
  }
}
