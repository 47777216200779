$primary-color: #002d5e;
$primary-color-gradient-start: #002d5e;
$primary-color-gradient-end: #1b5492;
$primary-color-hover: #082143;
$light-blue: #267dc0;
$secondary-color: white;
$secondary-text-color: #666666;

$primary-text-color: white;
$primary-theme-text-color: #292f36;
$primary-theme-text-color-hover: #3e4751;
$primary-theme-text-color-focus: #21262b;
$no-session-content-block-color: white;
$car-advise-orange: #f8991d;
$car-advise-orange-hover: #f8b21c;
$button-gradient-start-color: #fcc93e;
$button-gradient-end-color: #f8991d;
$gray: #666;
$very-light-gray: #f7f7f7;
$light-gray: #efefef;
$red: #ff2d2d;
$primary-button-color: #f8991d;
$primary-button-hover-color: #f68b00;
$secondary-button-color: #f7f7f7;
$secondary-button-hover-color: #fafafa;
$tertiary-button-color: #999999;
$tertiary-button-hover-color: #888888;
$secondary-button-text-color: #f8991d;
$tertiary-button-text-color: #f7f7f7;
$caradvise-electric-blue: #0390ff;
$neutral-color: rgb(235, 235, 235);
$header-text-color: $primary-color;
$modal-text-color: #f0f2f4;
$dashboard-link-color: #dc4017;
$primary-button-focus-color: #3363a0;
$primary-button-focus-text-color: #fff;
$button-link-color: #069;
$primary-theme-content-text-color: #586574;

$font-family: Muli, Helvetica, Arial, sans-serif !important;
$font-color: $secondary-text-color;

/* Recharts */
$recharts-primary-color: $light-blue;
$recharts-secondary-color: $car-advise-orange;
$recharts-neutral-color: rgb(235, 235, 235);
$recharts-negative-color: $red;

/* Sidebar icons */
$icon-color-active: white;
$icon-color-inactive: white;

$past-due-color: #ff0000;
$due-soon-color: #f5a623;
$active-navbar-tab-color: #3c4550;
$navbar-popup-text-color: #ffffff;
$black-text-color: #000000;
$alert-notice-background-color: #d9edf7;
$alert-notice-border-color: #bcdff1;
$alert-notice-text-color: #31708f;
$signin-form-label-color: #2f3036;
$payment-radio-checked-color: #fff;
$dash-stats-item-text-color: #00295f;
$dash-stats-item-span-text-color: #767676;
$primary-button-text-color: #f7f7f7;
$primary-theme-radio-border-color: #d7e4dd;
$primary-theme-radio-selected-color: #e1eae4;
$button-disabled-color: #fff;
$input-search-border-color: #ccc;
$orange-button-color: $primary-button-color;
$fleet-list-highlight-color: yellow;
$fleet-list-highlight-border-color: yellow;
$fleet-list-highlight-text-color: red;
$button-link-hover-color: #f3a18c;
$file-action-btn-color: #8194aa;
$file-action-btn-hover-color: #3d546f;
$file-action-btn-hover-background: #7ba8dc;
$button-alternate-text-color: #f8991d;
$toggle-checkbox-color: #2185d0;
$checkbox-background-color: #fff;
$transfer-vehicle-checkbox-background-color: #ff4081;
$checkbox-checked-color: rgba(0, 0, 0, 0.95);
$checkbox-border-color: #d4d4d5;
$notification-close-mark-color: #ffff;
$notification-success-background-color: #edfbd8;
$notification-success-text-color: #2b641e;
$notification-default-background-color: #eeeefe;
$notification-default-text-color: #0c2a75;
$notification-danger-background-color: #fce8db;
$notification-danger-text-color: #71192f;
$notification-info-background-color: #17a2b8;
$notification-warning-background-color: #eab000;
$menu-hover-color: $light-blue;
$orange-button-color-hover: $primary-button-hover-color;
$button-link-color: $primary-button-color;
$alert-default-color: #767676;
$alert-default-border-color: #bdbdbd;
$alert-default-background-color: #d5d5d5;
$alert-error-color: #a94442;
$alert-error-border-color: #ebcccc;
$alert-error-background-color: #f2dede;
$alert-success-color: #3c763d;
$alert-success-border-color: #d0e9c6;
$alert-success-background-color: #dff0d8;
$alert-warning-color: #8a6d3b;
$alert-warning-border-color: #faf2cc;
$alert-warning-background-color: #fcf8e3;
$manufacturer-title-checkbox-color: $primary-color;
$file-action-btn-color: #8194aa;
$file-action-btn-hover-color: #3d546f;
$react-switch-label-default-color: grey;
$segment-theme-background-color: #fff;
$text-alignment: center;
$segment-theme-border: unset;
$recommended-label-text-color: $secondary-color;
$modal-custom-radio-checked-color: $primary-color;
$ui-button-focus-border: #003c88;
$ui-button-focus-background-color: #003c88;
$checkbox-background-color: #fff;
$checkbox-checked-color: rgba(0, 0, 0, 0.95);
$toggle-checkbox-color: #2185d0;
$transfer-vehicle-checkbox-background-color: #ff4081;
$segment-theme-color: #00295f;
$notification-info-border-color: #c7c7c7;
