.forgot_password_form {
  margin: 0 auto;
  width: 360px;
}

.forgot_password_form__button {
  font-size: 18px;
  height: 60px;
  margin-bottom: 0;
  width: 100%;
}
