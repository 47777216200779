.approvals_form_field__approved_badge {
  background-color: $recharts-primary-color;
  color: $secondary-button-color;
}

.approvals_form_field__price_shop {
  color: $caradvise-electric-blue;
}

.approvals_form_fields_section__header {
  background-color: $recharts-primary-color;
}

.approvals_form_fields_section__header--primary {
  background-color: white;
  color: $recharts-primary-color;
  border-bottom: 2px solid #dddddd;
}

.approvals_form_fields_section__header--secondary {
  background-color: $recharts-primary-color;
  color: white;
  border-bottom: 2px solid #dddddd;
}

.approvals_form_fields_section__header--tertiary {
  background-color: white;
  color: $recharts-primary-color;
  border-bottom: 2px solid #dddddd;
}

.approvals_form_header__heading {
  color: $recharts-primary-color;
}

.approvals_form_header__sub_heading {
  color: $secondary-text-color;
}

.approvals_form_order_comments__icon {
  color: $recharts-primary-color;
}

.approvals_form_payment_method__link {
  color: $recharts-primary-color;
}

.approvals_form_totals__row--total {
  color: $recharts-primary-color;
}

.container-approvals {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  .box-container:last-child:nth-last-child(odd) {
    grid-column: auto / span 2;
  }

  .box-container:first-child:nth-last-child(even),
  .box-container:first-child:nth-last-child(even) ~ .box-container {
    grid-column: auto / span 1;
  }
  .maintenance-summary-section {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    padding: 20px;
  }

  .inline-elements {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-start;
    min-height: 48px;
    .title {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-flex-grow: 1;
      flex-grow: 1;
    }
    .action {
      height: 40px;
      margin-left: 4px;
      position: relative;
      cursor: pointer;
    }
  }
  .borderless {
    white-space: pre-wrap;
  }
}
