.carousel-container {
  overflow: hidden;

  .inner {
    white-space: nowrap;
    transition: transform 0.3s;
  }

  .carousel-items {
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    height: 150px;
    background-color: none;
    color: #fff;
    justify-items: center;
  }

  .carousel-item {
    display: grid;
    align-items: center;
    height: 150px;
    width: 90%;
    background-color: #ffe2e1;
    color: rgb(193, 10, 10);
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
    margin: auto;

    .carousel-header {
      display: flex;
      align-items: center;
      margin-top: 0;
    }
    .item-content-code {
      display: inline-flex;
      align-items: center;
      margin-left: 30px;
      width: 50%;
    }
    .item-content-interval {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 20px;
      width: 50%;
      color: #252525;
      font-weight: 400;
      text-transform: capitalize;
    }
    .item-content {
      width: 90%;
      margin-left: 30px;
      margin-top: -40px;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      white-space: break-spaces;
      line-height: 20px;
      box-sizing: border-box;
      pointer-events: none;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      span:after {
        content: "";
        display: block;
        position: absolute;
        top: 45px;
        right: 0;
        width: 50px;
        height: 15px;
        z-index: 9999;
        pointer-events: initial;
      }
      span:hover:after {
        cursor: pointer;
      }
    }
  }

  .indicators {
    display: flex;
    justify-content: center;
    margin: 10px;
  }

  .indicators > div {
    margin: 5px;
    background-color: #e9e9e9;
    height: 10px;
    width: 10px;
    border-radius: 50px;
    display: inline-block;
  }

  .indicators > div.active {
    background-color: #c10a0a;
    color: #fff;
  }
}
