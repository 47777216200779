.date_picker_field {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 60px;
  width: 100%;
  color: #999999;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 10px;
}

/* NOTE: This overrides the provided react-datepicker library styling, and forces it to behave like other application field elements. */
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}
