.vehicle-index-container {
  .ui.stretched.grid > .row > .column > * {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    background-color: $segment-theme-background-color;
    color: $segment-theme-color;
  }
  .dash-stats-item {
    text-align: $text-alignment;
  }
  .dash-stats-item span {
    color: $segment-theme-color;
  }
}

.vehicles-container {
  @media only screen and (max-width: 768px) {
    .menu-container {
      padding: 0;
    }
    .segment.menu-container > .ui.menu > .item {
      display: block;
      text-align: center;
    }
  }
  @media only screen and (max-width: 500px) {
    position: absolute;
    width: 138vw;
    .ui.segment.segment {
      width: initial;
    }
  }
}
.active_order__appointment {
  color: #0099ff;
  font-size: 16px;
  margin-top: 5px;
  padding: 5px 20px;
  font-weight: normal;
}

.active_order__dashboard_column {
  margin-right: 0px;
}

.active_order__column {
  font-size: 14px;
  color: #999999;
  background-color: #f7f7f7 !important;
}

.vehicle-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 5px;
  background-color: #ffffff;
  align-items: center;
  border: 1px solid #dddddd;
}

.vehicle-container:hover {
  background-color: #f7f7f7;
}

.vehicledetail-recall-icon {
  padding-top: 12px;
  padding-left: 20px;
}

.vehicledetail-recall-schedule {
  flex: 1;
  text-align: right;
  padding-top: 8px;
  padding-right: 20px;
}

.vehicledetail-recall-schedule a {
  display: inline-block;
}

.vehicledetail-recall-service {
  font-size: 14px;
  padding: 13px 15px 15px 15px;
}

.vehicle_form__date_field_container div:first-of-type {
  flex: 4;
}

.vehicle_form__field {
  height: 60px;
  color: #999999;
  font-size: 18px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  flex: 4;
}

.vehicle_form__field_container {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
}

.vehicle_form__field_label {
  flex: 1;
  font-size: 18px;
  padding: 20px;
}

.vehicle-info {
  flex: 2;
  font-weight: bold;
}

.vehicles-item {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 15px;
  flex-direction: column;
  font-weight: bold;
  text-align: left;
}

.vehicles-item span {
  display: inline-block;
  color: #999999;
  font-size: 12px;
  font-weight: normal;
}

.document-upload {
  flex-direction: column;
}

.document-upload-input {
  width: 94%;
  margin: 2.5% 0 2.5% 0;
}

.uploaded-image {
  width: 100%;
  height: 320px;
}

.uploaded-image-custom-doc {
  width: 100%;
  height: 350px;
}

.edit-document-button {
  margin-top: 25%;
}

.document-action-button {
  display: flex;
  justify-content: center;
}

.upload-daterange {
  display: flex;
  flex-direction: row;
  margin: 2.5% 0 2.5% 0;

  .upload-datepicker {
    display: inline-flex;
    vertical-align: middle;
    line-height: 2.5em;
    margin-right: 1em;
  }

  input {
    margin: 0;
    width: 22em;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    padding: 0.67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.42857143rem !important;
    -webkit-transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
    box-shadow: none;
  }
}

.upload-dropdown {
  margin: 2.5% 0 2.5% 0;
  .ui.selection.dropdown {
    min-width: 22em;
  }
}

.maintenance-history-links {
  color: #000000;
  cursor: pointer;
}

.invoice-documents {
  margin: 30px 10px;
  .invoice-document-name {
    margin-bottom: 10px;
    font-weight: 600;
  }

  .uploaded-document {
    width: 100%;
    height: 600px;
  }
}

.document-download-icon {
  float: right;
  font-size: 20px;
}
.document-count {
  margin-left: 10px;
}
.document-count-null {
  margin-left: 17px;
}

.file-atc-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .file-image {
    width: 130px;
    height: 85px;
    background-size: cover;
    border-radius: 5px;
    margin-right: 15px;
    background-color: #eaecf1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #475f7b;
    padding: 3px;
  }
  .file-image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
  }
  .file-detail {
    flex: 1;
    width: calc(100% - 210px);
  }
  .file-detail h6 {
    word-break: break-all;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .file-detail a[target="_blank"]:not(.notarget):after {
    display: none;
  }

  .file-detail p {
    font-size: 12px;
    color: #8194aa;
    line-height: initial;
    font-weight: 400;
    margin-bottom: 8px;
  }
  .file-actions {
    // display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
  }
  .file-action-btn,
  a {
    font-size: 12px;
    color: $file-action-btn-color;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    text-decoration: none;
    margin-right: 15px;
    padding: 2px 10px;
    cursor: pointer;
  }
  .file-action-btn:hover {
    color: $file-action-btn-hover-color;
    text-decoration: none;
    background-color: $file-action-btn-hover-background;
    border-radius: 3px;
  }
  a:hover {
    color: $file-action-btn-hover-color !important;
    text-decoration: underline;
  }
  .file-atc-box:last-child {
    margin-bottom: 0;
  }
}

.download-all-btn {
  font-size: 14px;
  color: $file-action-btn-color;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
  float: right;
  position: relative;
  bottom: 20px;
}

.download-all-btn:hover {
  color: $file-action-btn-hover-color;
  text-decoration: underline;
}

.segment.ag-theme-material {
  height: auto !important;
  .ag-root-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    height: 50vh;
  }

  label::after {
    background: $checkbox-background-color;
    border-radius: 3px;
    border: 1.5px solid $checkbox-background-color !important;
  }

  input:checked ~ label:after {
    color: $checkbox-checked-color;
    border: 1px solid $checkbox-border-color !important;
    font-size: 12px;
  }

  input:focus:checked ~ label:after {
    color: $checkbox-checked-color;
    border: 1px solid $checkbox-border-color;
  }
}

.vehicle-shimmer-parent {
  height: 100%;
  width: 100%;
  padding: 1%;
}

.ui.toggle.checkbox input:checked ~ label:before {
  background-color: $toggle-checkbox-color !important;
}

.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: $toggle-checkbox-color !important;
}

.ag-theme-material .ag-radio-button-input-wrapper.ag-checked::after {
  color: $transfer-vehicle-checkbox-background-color !important;
}

.ui.toggle.checkbox label:before {
  background-color: $react-switch-label-default-color !important;
}

.ui.toggle.checkbox input:checked ~ label:before {
  background-color: $toggle-checkbox-color !important;
}

.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: $toggle-checkbox-color !important;
}

.ag-theme-material .ag-radio-button-input-wrapper.ag-checked::after {
  color: $transfer-vehicle-checkbox-background-color !important;
}

.bulk-vehicle-portal {
  position: fixed !important;
  left: 50%;
  top: 94%;
  padding: 16px !important;
  transform: translate(-50%, -50%);
  z-index: 99;
  border-radius: 16px !important;
  background: #f5f5f5 !important;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.18) !important;

  @media screen and (max-width: 1024px) {
    left: 53%;
  }
}

.flexCenter {
  display: flex;
  align-items: center;
}

.vehicle-search {
  display: flex;
  gap: 5px;
  align-items: center;
}

.column-vin {
  display: flex;
  align-items: self-start;
  gap: 5px;
}

.vin-copy {
  width: 12px;
  height: 12px;
  margin-top: 2px;
  cursor: pointer;

  .st0 {
    fill: rgb(0, 0, 0);
  }
}

.vin-copy-tooltip {
  padding: 5px;
  top: 10px !important;
}
