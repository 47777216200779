@mixin labelStyle {
  color: #2f3036;
  font-family: "Catamaran", sans-serif !important;
  font-weight: 700;
  font-size: 14px;
  margin: 0;
}

@mixin fieldError {
  width: 100%;
  padding: 10px;
  color: red;
}

@mixin flexAlign {
  display: flex;
  align-items: center;
}

@mixin achModalInput {
  width: 100%;
  input {
    padding: 12px 16px;
    border-radius: 4px !important;
  }
}

@mixin submitButton {
  width: 100%;
  padding: 16px 20px !important;
  border-radius: 4px;
  margin-top: 15px;
}

@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

.ach_modal {
  padding: 5px;

  .ach_error {
    @include fieldError;
  }

  .ach_modal__header {
    @include flexAlign;
    justify-content: space-between;
    padding-bottom: 18px;
    align-items: start;

    .ach_modal__payment {
      p {
        margin: 0;
      }
      .payment_header {
        color: $primary-color;
        font-weight: 700;
        font-size: 20px;
      }
      .payment_subheader {
        color: #616161;
        font-size: 16px;
        font-weight: 400;
      }
    }

    .closeLogo {
      cursor: pointer;
    }
  }

  .ach_modal__title {
    @include flexAlign;
    gap: 10px;
    .ach_title {
      color: $primary-color;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .ach_modal__container {
    .ach_modal__form {
      text-align: start;
      margin: 20px 0;
      width: 100%;

      .ach_modal__label {
        @include labelStyle;
      }

      .radio_label__width {
        @include labelStyle;
        width: 8.7rem;
      }

      .ach_modal__user {
        @include flexAlign;
        gap: 20px;

        .ach_modal__userinput {
          flex: 1;
          input {
            padding: 12px 16px;
            border-radius: 4px !important;
            width: 50% !important;
          }
        }
      }

      .ach_modal__input {
        @include achModalInput;
        input[type="number"] {
          @include appearance(textfield);
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          @include appearance(none);
          margin: 0;
        }
      }

      .ach_form__radio {
        margin: 10px 0;
        display: flex;
        align-items: center;
        gap: 20px;

        .ach_account__type {
          width: 100px;
          label {
            padding-top: 2px;
            padding-left: 29px;
            font-size: 15px;
          }
          label::before {
            border: 2px solid $primary-color;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin: 0;
          }
          input:checked ~ label:before {
            color: #fff !important;
            border: 1px solid $primary-color !important;
            width: 20px;
            height: 20px;
          }
          input:checked ~ label:after {
            color: #fff !important;
            background-color: $primary-color !important;
            border: 1px solid $primary-color !important;
            border: 2px solid $primary-color;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin: 0;
          }
        }
      }

      .ach_modal__select {
        width: 100%;
        min-width: 100% !important;
      }
    }

    .ach_modal__error {
      @include fieldError;
    }

    .ach_modal__userform {
      @include flexAlign;
      gap: 20px;
      margin-bottom: 30px;

      .user_form__container {
        flex: 1;
        .ach_modal__label {
          @include labelStyle;
        }

        .ach_modal__error {
          @include fieldError;
        }

        .ach_modal__input {
          @include achModalInput;
        }
      }
    }

    .default_consent {
      .consent_text {
        input:checked ~ label:after {
          color: #fff !important;
          background-color: #e95832 !important;
          border: 1px solid #e95832 !important;
          border-radius: 4px !important;
        }
      }
    }
  }

  .ach_modal__button {
    @include submitButton;
    background-color: $primary-color !important;
    color: #fff !important;

    &:hover,
    &:focus,
    &:active {
      background-color: $primary-color !important;
      color: #fff !important;
    }
  }

  .ach_modal__disabled {
    @include submitButton;
    background-color: #bdbdbd !important;
    color: #616161 !important;

    &:hover {
      background-color: #bdbdbd !important;
      color: #616161 !important;
    }
  }

  .choose_different__method {
    background-color: #f8f8f8;
    text-decoration: underline;
    font-weight: 700;
    font-size: 16px;
    padding: 16px;
    color: $primary-color;
    text-align: center;
    border-radius: 4px;
    margin: 15px 0;
    cursor: pointer;
  }

  .braintree__dropin {
    display: none !important;
  }
}

.ach_modal__bg {
  background-color: #f8f8f8;
  padding: 16px;
  border: 1px solid #e4e4e4;
}
