.header-legacy {
  display: flex;
  height: 40px;
  padding: 10px 50px;
  position: relative;
}

.header__affiliation_name {
  color: #00295f;
  font-size: 32px;
}

.header__links {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 43px;
}

.header__sign_out_link {
  color: #f8991d;
  cursor: pointer;
  text-decoration: none;
}

.header__top_row {
  margin-bottom: 5px;
}

.header__user_name {
  color: #00295f;
  font-size: 16px;
  margin-top: 8px;
}
