.fleet_form {
  color: #999999;
  display: flex;
  font-size: 14px;
  padding: 20px;
  margin-bottom: 30px;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  border: 1px solid #dddddd;
}
