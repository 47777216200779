.magic-link-container {
  color: #343434;
  display: flex;
  flex-direction: row;
  background: white;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
  width: -webkit-fill-available;
  width: -moz-available;
  @media only screen and (max-width: $break-large) {
    flex-direction: column;
    width: auto;
  }
}

.magic-link-left-container {
  display: flex;
  flex: 1;
  flex-basis: 50%;
  width: 50vw;
  background-color: #f0f2f4;
  flex-direction: column;
  justify-content: space-evenly;
  > div {
    display: flex;
    flex-direction: column;
    flex: 1;

    &:first-child {
      justify-content: center;
      svg {
        max-width: 225px;
      }
    }

    &:last-child {
      flex: 2;
    }
  }
  .logo-image {
    position: relative;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
  }
  svg.ca-logo {
    max-width: 400px !important;
  }
  @media (max-width: 500px) {
    display: none;
  }
}

.magic-link-right-container {
  flex: 1;
  flex-basis: 50%;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .contentCenter {
    box-shadow: none;
    display: flex;
    padding: 24px 14px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
    position: relative;
    background-color: transparent;
    margin: 0;
    min-width: 77%;
    .magic-link-options-container {
      display: flex;
      padding: 0px;
      flex-direction: column;
      align-items: center;
      gap: 44px;
      width: 83%;
      @media (max-width: 500px) {
        gap: 24px;
        width: auto;
      }
      .magic-link-description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        width: 95%;
        .magic-link-header-text {
          color: #282828;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-align: left;
          align-self: stretch;
        }
        .magic-link-meta-text {
          color: #616161;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: left;
          align-self: stretch;
        }
        @media (max-width: 500px) {
          width: auto;
          .magic-link-header-text {
            font-size: 18px;
            align-self: stretch;
          }
          .magic-link-meta-text {
            font-size: 14px;
            align-self: stretch;
          }
        }
      }
      .magic-link-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        width: -webkit-fill-available;
        width: -moz-available;
        .magic-link-button {
          display: flex;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 4px !important;
          background: $primary-color !important;
          color: #ffffff !important;
          text-align: center;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.41px;
          width: 100%;
          height: 42px;
          margin: 0;
          .magic-link-button-icon {
            padding: 5px 0px 0px 1px;
          }
          .injected-svg {
            path {
              stroke: white;
            }
          }
        }
        .magic-link-button:hover,
        .magic-link-button:active,
        .magic-link-button:focus {
          background: $primary-color;
          color: #ffffff !important;
        }
        .white-link-button {
          display: flex;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 4px !important;
          background: #f4f7f5;
          text-align: center;
          color: #555;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.41px;
          width: auto;
          height: auto;
          margin: 0;
        }
      }
    }

    //success/error redirections styles
    .magic-link-redirect-container {
      display: flex;
      padding: 0px;
      flex-direction: column;
      align-items: center;
      gap: 44px;
      width: 83%;
      @media (max-width: 500px) {
        width: auto;
        padding: 80px 14px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        flex-shrink: 0;
        justify-content: space-between;
        height: 80%;
      }

      .magic-link-type-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        width: 95%;

        .magic-link-header-text {
          color: #282828;
          text-align: center;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
        .magic-link-header-text.error {
          font-size: 18px;
          font-style: normal;
          @media (max-width: 500px) {
            width: 360px;
          }
        }
        .magic-link-text {
          color: #3c3c3c;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          width: 100%;
        }
        .magic-link-text.error {
          color: #3c3c3c;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          width: 100%;
        }
        .magic-link-text-description {
          color: #3c3c3c;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          width: 350px;
        }
      }
      .redirect-button-container {
        display: flex;
        padding-top: 135px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        width: 360px;
        .timer-span {
          display: flex;
          gap: 8px;
        }
        .timer-text {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.41px;
        }
        .button-link-orange {
          color: $button-link-color;
          text-decoration: underline;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.41px;
          text-decoration-line: underline;
          text-align: center;
          border: none;
          background: transparent;
          cursor: pointer;
          @media only screen and (max-width: $break-small) {
            text-align: justify;
          }
        }
        .button-link-orange.disabled {
          opacity: 0.5;
          pointer-events: none;
          cursor: not-allowed;
        }
        .magic-link-button {
          display: flex;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 4px !important;
          background: #f4f7f5;
          text-align: center;
          color: #555;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.41px;
          width: auto;
          height: auto;
          margin: 0px;
        }
      }
      .option-content {
        display: flex;
        flex-direction: column-reverse;
        gap: 16px;
        width: 100%;
        align-items: flex-start;
        .input-container {
          position: relative;
          width: -webkit-fill-available;
          width: -moz-available;
          .inputFld {
            width: inherit;
            height: auto;
            font-size: 16px;
            color: #666;
            display: flex;
            padding: 12px 16px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 4px;
            border: 1px solid #747373;
            background: #ffffff;
            margin-bottom: 0px;
            padding-left: 30px;
          }
          .plus-one {
            position: absolute;
            font-size: 16px;
            top: 49%;
            transform: translateY(-50%);
            left: 10px;
            pointer-events: none;
            color: #666;
          }
          input::placeholder {
            color: #979797;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
          .inputFld:focus {
            outline: none;
          }
        }

        .option-helper-text {
          color: #616161;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          text-align: left;
        }

        @media (max-width: 500px) {
          flex-direction: column-reverse;
        }
      }
    }

    .magic-link-redirect-container.error {
      display: flex;
      padding: 0px;
      flex-direction: column;
      align-items: center;
      gap: 44px;
      width: 83%;

      .magic-link-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        width: -webkit-fill-available;
        width: -moz-available;
        .magic-link-button {
          display: flex;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 4px !important;
          background: $primary-color !important;
          color: #ffffff !important;
          text-align: center;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.41px;
          width: 100%;
          height: 42px;
          margin: 0;
          .magic-link-button-icon {
            padding: 5px 0px 0px 1px;
          }
          .injected-svg {
            path {
              stroke: white;
            }
          }
        }
        .magic-link-button:hover,
        .magic-link-button:active,
        .magic-link-button:focus {
          background: $primary-color;
          color: #ffffff !important;
        }
        .white-link-button {
          display: flex;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 4px !important;
          background: #f4f7f5;
          text-align: center;
          color: #555;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.41px;
          width: auto;
          height: auto;
          margin: 0;
        }
      }
      @media (max-width: 500px) {
        width: auto;
        padding: 80px 14px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        flex-shrink: 0;
        justify-content: flex-start;
        height: 90%;
      }
    }

    .orange-button {
      background-color: $primary-button-color;
      box-shadow: none;
      border: none;
      margin-left: auto !important;
      margin-right: auto !important;
      min-width: -webkit-fill-available !important;
      min-width: -moz-available !important;
      padding: 10px;
      border-radius: 4px;
      height: 42px !important;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.41px;
    }
    @media (max-width: 500px) {
      height: 90vh;
      position: relative;
    }
  }

  @media (max-width: 500px) {
    width: 100vw !important;
    position: relative;
    height: 75vh;
  }
}

.responsive-small-magic-link {
  .header-logo {
    display: flex;
    margin: 50px auto 0px auto;
    width: -webkit-fill-available;
    width: -moz-available;
    justify-content: center;
    height: 31px;

    @media only screen and (min-width: $break-large) {
      display: none;
    }
  }

  @media only screen and (min-width: $break-large) {
    display: none;
  }
}

.magic-link-error-container {
  // background: #ffffff;
  // position: absolute;
  // top: 40%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  display: grid;
  width: 100%;
  background: white;
  height: 100vh;
  place-items: center;

  .error-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 500px;

    .error-image {
      margin: 0;
    }
    .error-info-content {
      display: flex;
      flex-direction: column;
      gap: 33px;
      align-items: center;
      .title-text {
        color: #282828;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.592px;
      }
      .meta-text {
        color: #616161;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.592px;

        .button-link-orange {
          color: $button-link-color;
          text-decoration: underline;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.41px;
          text-decoration-line: underline;
          text-align: center;
          border: none;
          background: transparent;
          cursor: pointer;
        }
      }
    }
    .links {
      margin: 20px 0px;
      .magic-link-buttons {
        .magic-link-button {
          margin: auto 20px;
        }
        .white-link-button {
          margin: auto 20px;
        }
      }
    }
  }

  .magic-link-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: -webkit-fill-available;
    width: -moz-available;
    .magic-link-button {
      display: flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 4px !important;
      background: $primary-color !important;
      color: #ffffff !important;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.41px;
      width: 100%;
      height: 42px;
      margin: 0;
      .magic-link-button-icon {
        padding: 5px 0px 0px 1px;
      }
      .injected-svg {
        path {
          stroke: white;
        }
      }
    }
    .magic-link-button:hover,
    .magic-link-button:active,
    .magic-link-button:focus {
      background: $primary-color;
      color: #ffffff !important;
    }
    .white-link-button {
      display: flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 4px !important;
      background: #f4f7f5;
      text-align: center;
      color: #555;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.41px;
      width: auto;
      height: auto;
      margin: 0;
    }
  }

  @media (max-width: 1366px) {
    top: 50%;
  }
  @media (max-width: 500px) {
    width: 100vw;
    top: 40%;
    .error-content {
      width: 100vw;
      .error-image {
        margin-left: 20%;
        margin-right: 0px;
        width: 80%;
      }
      .error-info-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: self-start;
        margin: auto 30px;
        .title-text {
          font-size: 18px;
        }
        .meta-text {
          font-size: 14px;
        }
      }
    }
  }
}

.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f1f2f1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9999;
}
.loaderText {
  display: flex;
  font-size: 20;
  color: #ffffff;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  .loader-info-text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #282828;
    font-style: normal;
    font-family: "Catamaran", sans-serif !important;
    left: 20px;
    top: 130px;
    .loader-text-title {
      font-size: 24px;
      max-width: 365px;
      font-weight: 700;
      line-height: 72px;
    }
    .loader-text-subtext {
      font-size: 18px;
      max-width: 430px;
      font-weight: 400;
      line-height: 22px;
    }
    .subtext-item {
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      display: flex;
      height: 50px;
    }
    .text-animation {
      opacity: 1;
    }

    @media (max-width: 500px) {
      left: 0px;
    }
  }
  @media (max-width: 500px) {
    top: 35%;
  }
}

.loaderText-image-only {
  display: flex;
  font-size: 20;
  color: #ffffff;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-content-full {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @keyframes heartbeat {
    0% {
      transform: scale(0.06);
    }
    50% {
      transform: scale(0.05);
    }
    100% {
      transform: scale(0.06);
    }
  }
  @media (max-width: 500px) {
    @keyframes heartbeat {
      0% {
        transform: scale(0.2);
      }
      50% {
        transform: scale(0.3);
      }
      100% {
        transform: scale(0.2);
      }
    }
  }

  .loader-image {
    animation: heartbeat 1s infinite;
  }
}
