.calendar__select {
  max-width: 525px;
}

.row-total {
  display: flex;
  flex: 1;
  background-color: #f7f7f7;
  justify-content: space-between;
  padding: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}

.service_request__go_back_container {
  margin: 0 10%;
  height: 40px;
}

.service_request__item_name {
  padding-top: 4px;
}

.service_request__item_price {
  padding-top: 4px;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 2px solid #cccccc;
}

.service_request__item_total {
  padding-right: 142px;
}

.service_request__options_select {
  height: 20px;
  width: 150px;
  color: #999999;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
}

.service_request__options_select_container {
  padding-bottom: 10px;
  padding-left: 40px;
  background-color: #ffffff;
}

.service_request__schedule_form_container {
  margin: 0 10% !important;
}

.service_request_summary {
  background-color: #ffffff;
  padding: 20px;
  margin: 0 10%;
  margin-bottom: 30px;
  font-size: 18px;
  border: 1px solid #dddddd;
}

.service_request_summary__label {
  text-align: right;
  font-weight: bold;
}

.service_request_summary__value {
  min-height: 20px;
  border-bottom: 1px solid #dddddd;
}

.service_request_summary__service {
  margin-bottom: 5px;
}

.service_request_summary__service:last-of-type {
  margin-bottom: 0;
}
