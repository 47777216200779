.membership {
  background-color: $primary-color;
  border-radius: 4px;

  @media print {
    background-color: $primary-color !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .membership_header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .membership_header__details {
      flex: 1;
      .membership_name {
        padding: 10px 0 0 10px;
        color: #e3eae6;
        font-weight: 700;
        font-size: 22px;
        margin: 0 0 1rem 0;
        font-family: $font-family;
      }

      .membership_driver {
        font-weight: 500;
        font-size: 16px;
        color: #e3eae6;
        margin: 0;
        padding: 10px;
      }
    }

    .fleetadvise_logo {
      background-color: #e3eae6;
      border-radius: 0 0 0 6px;
      padding: 15px;
    }
  }

  .membership_vehicle {
    background-color: #e3eae6;
    border-radius: 6px 0 0 6px;
    margin-left: 12px;
    margin-top: 15px;
    padding: 9px;
    p {
      margin: 0;
      font-size: 16px;
      font-weight: 700;
      padding: 5px 0;
      color: #292f36;

      span {
        font-weight: 500;
      }
    }
  }

  .membership_code {
    background-color: #e3eae6;
    padding: 10px;
    margin: 25px 0;
    gap: 25px;

    .shop-instruction-label {
      font-size: 22px;
      font-weight: 700;
      color: #292f36;
      margin-bottom: 8px;
    }

    .membership_code__details {
      color: #292f36;
      font-weight: 500;
      font-size: 14px;

      span {
        font-weight: 700;
      }
    }
  }

  .membership_brand {
    padding: 0 10px 18px 10px;
    .mebership_brand__store {
      color: #e3eae6;
      font-weight: 500;
      font-size: 14px;
    }

    .membership_shops {
      width: fit-content;
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      align-items: center;

      img {
        width: fit-content;
      }
    }
  }
}
