$catamaran: "Catamaran", sans-serif;

.customize-report-modal {
  width: 450px !important;

  .content {
    padding: 30px 30px 30px 30px !important;

    .customize-options {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }

    .option-checkbox {
      color: #292f36;
      font-family: $catamaran;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;

      & input:checked ~ label::before {
        background-color: #e95832 !important;
        color: #fff !important;
      }

      & input:checked ~ label::after {
        background-color: #e95832 !important;
        color: #fff !important;
      }
    }

    .report-modal-header {
      text-align: center;
      font-weight: 600;
      color: #101828;
      font-size: 18px;
      font-family: $catamaran;
    }

    .dialog-action-btn {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-top: 5rem;

      .cancel-btn {
        flex: 1;
        border-radius: 8px !important;
        background: #d1e0d8 !important;
        color: #21262c !important;
        font-family: $catamaran !important;
        font-size: 18px !important;
        font-weight: 500 !important;
        padding: 14px !important;
      }

      .download-btn {
        flex: 1;
        border-radius: 8px !important;
        background: #292f36 !important;
        color: #fff !important;
        font-family: $catamaran !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        padding: 16px !important;
      }
    }
  }
}
