.alert {
  border: 1px solid;
  border-radius: 6px;
  font-size: 18px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;

  color: #767676;
  border-color: #bdbdbd;
  background-color: #d5d5d5;
}

//this media query is for Alert on the signup page for screensize less than 325 only.
@media screen and (max-width: 325px) {
  .alert--signup--page {
    width: 88%;
  }
}

.alert--default {
  color: $alert-default-color;
  border-color: $alert-default-border-color;
  background-color: $alert-default-background-color;
}

.alert--error {
  background-color: $alert-error-background-color;
  border-color: $alert-error-border-color;
  color: $alert-error-color;
}

.alert--notice {
  background-color: $alert-notice-background-color;
  border-color: $alert-notice-border-color;
  color: $alert-notice-text-color;
}

.alert--success {
  background-color: $alert-success-background-color;
  border-color: $alert-success-border-color;
  color: $alert-success-color;
}

.alert--warning {
  background-color: $alert-warning-background-color;
  border: 1px solid $alert-warning-border-color;
  color: $alert-warning-color;
}
