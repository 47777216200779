.link {
  color: $button-link-color;
}

.primary-color {
  color: $primary-color !important;
}

.positive-color {
  color: $light-blue !important;
}

.negative-color {
  color: $red !important;
}

.neutral-color {
  color: $neutral-color !important;
}

.dashboard-link-color {
  color: $dashboard-link-color;
}

.user-list-actions {
  cursor: pointer;
  margin-top: -6px;
}

.vehicle_field {
  font-weight: 700;

  span {
    font-weight: 200;
  }
}
