.manage__account {
  .segment__custom-class {
    margin: auto;
    box-shadow: none;
    border: none;

    input,
    div {
      cursor: pointer !important;
    }

    .fleetcore__select,
    .fleetcoreAccount__select {
      display: flex;
      align-items: center;
      gap: 8px;

      .dropdown__logo {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #f0f2f4;
        padding: 4px;
      }

      .text {
        color: #4a4a4a;
        font-family: "catamaran", sans-serif;
        font-size: 19px;
        font-style: normal;
        font-weight: 700;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .visible {
        min-width: 28rem !important;
        border: none;
        top: 2rem;

        .text {
          color: #292f36 !important;
          font-family: "catamaran", sans-serif;
          font-size: 16px !important;
          font-style: normal;
          font-weight: 500 !important;
          min-width: 100%;
        }
      }
    }

    .fleetcoreAccount__select {
      font-size: 24px;
      font-weight: 700;
      margin-left: 1em;

      .text {
        color: $header-text-color !important;
        font-size: 22px;
        max-width: 250px;
        white-space: unset;
        overflow: unset;
        text-overflow: unset;
      }
    }
  }
}

.dropdown_options {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }
}
