.activity_indicator,
.activity_indicator:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.activity_indicator {
  /* Size of spinner */
  font-size: 1.6px;
  position: relative;
  text-indent: -9999em;
  /* Alternate color of spinner and thickness */
  border-top: 1.1em solid rgba(255, 255, 255, 1);
  border-right: 1.1em solid rgba(255, 255, 255, 1);
  border-bottom: 1.1em solid rgba(255, 255, 255, 1);
  /* Primary color of spinner */
  border-left: 1.1em solid #082346;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  /* Speed at which it takes to do one rotation (The lower the number, the faster). */
  -webkit-animation: load8 2s infinite linear;
  animation: load8 2s infinite linear;
}

.custom-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 14px;
  p {
    line-height: 0.5;
  }
  .text {
    color: $recharts-secondary-color;
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
