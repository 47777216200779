$break-xsmall: 350px;
$break-small: 425px;
$break-medium: 720px;
$break-large: 950px;
$break-xlarge: 1440px;

.visible-md-up {
  display: none;
}

.visible-md-down {
  display: none;
}

.visible-sm {
  display: none;
}

@media only screen and (max-width: ($break-large - 1px)) {
  .visible-md-down {
    display: unset;
  }
}

@media only screen and (min-width: ($break-small+1px)) {
  .visible-md-up {
    display: unset;
  }
}

@media only screen and (max-width: ($break-small)) {
  .visible-sm {
    display: unset;
  }
}
