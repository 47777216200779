.fleet_filter__button {
  font-size: 14px;
  height: 45px;
  margin-bottom: 0;
}

.fleet_filter__container {
  margin: 0 30px 15px 30px;
  text-align: right;
}

.fleets_filter__no_results {
  margin-top: 100px;
}

.fleet_filter__select {
  margin-right: 10px;
  width: 20%;
}

.fleet_filter__text_field {
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin-right: 10px;
  width: 20%;
}
