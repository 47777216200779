:root {
  --card-line-height: 1.2em;
  --card-padding: 1em;
  --card-radius: 0.5em;
  --color-orange: #e95832;
  --color-gray: #e2ebf6;
  --color-dark-gray: #c4d1e1;
  --radio-border-width: 1px;
  --radio-size: 1.3em;
}

.options-render-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 16px;

  @media (min-width: 42em) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.option-card {
  position: relative;
  border-radius: 4px;
  border: 1px solid #747373;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: -webkit-fill-available;
  width: -moz-available;

  .option-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    align-items: flex-start;
    .input-container {
      position: relative;
      width: -webkit-fill-available;
      width: -moz-available;
      .inputFld {
        width: inherit;
        height: auto;
        font-size: 16px;
        color: #666;
        display: flex;
        padding: 12px 16px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 4px;
        border: none;
        background: #ffffff;
        margin-bottom: 0px;
        padding-left: 30px;
      }
      .plus-one {
        position: absolute;
        font-size: 16px;
        top: 49%;
        transform: translateY(-50%);
        left: 10px;
        pointer-events: none;
        color: #666;
      }
      input::placeholder {
        color: #979797;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
      .inputFld:focus {
        outline: none;
      }
    }
    .option-helper-text {
      color: #616161;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
    }

    @media (max-width: 500px) {
      flex-direction: column-reverse;
    }
  }

  .options-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .options-details {
      display: flex;
      width: max-content;
      align-items: center;
      gap: 16px;
      padding: 0;

      .option-icon {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }

      .option-label {
        color: #424c57;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
}

.option-radio {
  font-size: inherit;
  margin: 0;
  position: absolute;
  right: calc(var(--card-padding) + var(--radio-border-width));
  // top: calc(var(--card-padding) + var(--radio-border-width));
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .option-radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    border: var(--radio-border-width) solid $primary-color;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    transition: background 0.2s ease-out, border-color 0.2s ease-out;
    width: 16px;
    height: 16px;

    &::after {
      border: 3px solid #ffffff;
      border-top: 20;
      border-left: 30;
      border-radius: 50%;
      content: "";
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:checked {
      border-color: var(--color-orange);
    }
  }

  .option-card:hover .option-radio {
    border-color: var(--color-orange);

    &:checked {
      border-color: var(--color-orange);
    }
  }
}

.option-card:hover {
  border-color: var(--color-orange);
}

.option-radio:checked {
  border-color: var(--color-orange);
  background-color: var(--color-orange);
}
.option-card.selected {
  background-color: #fef6f4;
  border-color: var(--color-orange);
  gap: 16px;
}

.option-radio:focus {
  box-shadow: 0 0 0 2px var(--color-orange);
}

.option-radio:disabled {
  color: var(--color-dark-gray);
  cursor: default;
}

.option-radio:disabled {
  color: var(--color-dark-gray);
}

.option-card:hover .option-radio:disabled {
  border-color: var(--color-gray);
  box-shadow: none;
}

.option-card:hover .option-radio:disabled {
  border-color: var(--color-gray);
}
