.recall-info {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.recall-icon {
  padding-top: 7px;
}

.recall-schedule {
  flex: 1;
  text-align: right;
  padding-right: 20px;
}

.recalls-top {
  background-color: #f7f7f7 !important;
}
