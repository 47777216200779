@import "variables";
$primary-color-gradient-start: #005ca5;
$primary-color: #005ca5;
$primary-color-light: #e6e6fa;
$primary-color-gradient-end: #005ca5;
$primary-color-hover: #00abe1;
$secondary-color: $primary-color;
$manufacturer-title-checkbox-color: $primary-color;
$recommended-label-text-color: $secondary-color;
$modal-custom-radio-checked-color: $primary-color;
$button-disabled-background-color: #f8991d;
$link-color: $primary-color;
