.quick_link {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 10px;
  min-height: 110px;
}

.quick_link__column {
  margin-right: 20px;
}

.quick_link__disclaimer {
  padding: 0 0 30px 20px;
}

.quick_link__text_field {
  height: 36px;
}

.quick_links__container {
  margin-bottom: 30px;
}

.quick_links__table_header {
  padding: 0 20px;
  margin-bottom: 10px;
  color: #999999;
}
