.service-container {
  margin: 8px 0;
  .service-name {
    font-size: 15px;
    margin: 0;
  }
  .service-notes {
    font-weight: 600;
    margin: 0;

    span {
      font-weight: 200;
    }
  }
}
