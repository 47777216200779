.bulk__active-deactive {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  .vehicle__selected {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    width: 20%;
  }

  .vehicle__active,
  .vehicle__deactive {
    display: flex;
    width: 184px !important;
    height: 47px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 7px !important;
    box-shadow: none !important;

    &:hover {
      box-shadow: none !important;
    }
  }

  .vehicle__active {
    background: $primary-color !important;
    color: #fff !important;
    margin-left: 1rem;

    &:hover,
    &:disabled {
      background: $primary-color !important;
      color: #fff !important;
    }
  }

  .vehicle__deactive {
    background: #fff !important;
    color: #000 !important;
    filter: none !important;

    &:hover,
    &:disabled {
      background: #fff !important;
      color: #000 !important;
    }
  }
}
