.appt-time {
  color: #0099ff;
  font-size: 16px;
  margin-top: 5px;
  padding: 5px 0;
  font-weight: normal;
}

.maintenance_approval {
  display: flex;
  flex: 1;
  flex-direction: row;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  justify-content: space-between;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
}

.maintenance_approval__container {
  margin-bottom: 5px;
}

.maintenance_approval__name {
  flex: 7;
  padding-left: 5px;
  padding-right: 5px;
}

.maintenance_approval__notes {
  border: 1px solid #dddddd;
  border-top: 0px;
}

.maintenance_approval__labor_price {
  flex: 1;
  min-width: 75px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: right;
}

.maintenance_approval__parts_price {
  flex: 1;
  min-width: 75px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: right;
}

.maintenance_approval__price {
  flex: 1;
  min-width: 75px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: right;
}

.maintenance_approval__status {
  flex: 2;
  min-width: 70px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

.maintenance_approval__status_label {
  color: #999999;
}

.maintenance_approval__submit_button {
  width: 80px;
}

.approvals_form_fields_section {
  overflow: initial;
}

.rejection-reasons-dropdown {
  max-width: 500px;
}
.credit-card-dropdown {
  margin-top: 5px;
  max-width: 200px;
  font-weight: normal;
}

.ui.fluid.selection.dropdown.credit-card-dropdown {
  max-width: 390px;
  max-height: 40px;
  padding-bottom: 17px;
}

.approvals_form_summary__column--right {
  overflow: visible;
}

@media only screen and (min-width: 768px) {
  /* For mobile phones: */
  .ui.fluid.selection.dropdown.credit-card-dropdown {
    margin-right: 10px;
    margin-left: 10px;
  }
  .label-of-dropdown {
    padding-top: 15px;
  }
}
