.settings-container {
  .segment.tab-container {
    height: auto;
  }
  .ui.grid .row + .ui.divider {
    width: 53vw;
  }
  .user-list-content {
    overflow: auto;
    height: 65vh;
  }

  .fmc-vehicle-table-height {
    min-height: 80.5vh !important;

    .ag-root-wrapper {
      height: 74vh !important;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  @media only screen and (max-width: 991px) {
    .ui.grid > .row {
      width: 80% !important;
    }
    .ui.segment.segment {
      width: inherit;
    }
    .ui.grid .row + .ui.divider {
      width: 76vw;
    }
  }

  @media only screen and (max-width: 768px) {
    .menu-container {
      padding: 0;
    }
    .ui.segment.segment {
      width: inherit;
    }
    .segment.menu-container > .ui.menu > .item {
      display: block;
      text-align: center;
    }
    .ui.grid > .row {
      width: 100% !important;
    }
    .ui.grid .row + .ui.divider {
      width: 95%;
    }
  }

  @media only screen and (max-width: 500px) {
    position: absolute;
    width: 138vw;
    .ui.segment.segment {
      width: inherit;
    }
    .ui.grid > .row {
      width: 100% !important;
    }
    .ui.grid .row + .ui.divider {
      width: 95%;
    }
  }
}

.notifications-reminder-container {
  width: 100%;
  h3 {
    color: #000000;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.46px;
  }

  .maintenance-reminders-notifications {
    display: flex;
    flex-direction: column;
  }
  .icon-style {
    margin-left: 30px;
  }
  .lable {
    color: #000000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }
  .label-meta {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.5px;
  }
}

.fmc-activerorders-container {
  .fmc-active-order-height {
    height: 75vh !important;
  }

  .ag-root-wrapper {
    height: 72vh !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
