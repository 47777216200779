.current-location__container {
  align-items: center;
  background-color: #fff;
  border-right: 1px solid $light-blue;
  border-bottom: 1px solid $light-blue;
  border-left: 1px solid $light-blue;
  display: flex;
  height: 40px;
  padding: 0 10px;

  &:hover {
    cursor: pointer;
  }
}

.current-location__icon {
  color: $car-advise-orange;
}

.current-location__container--hidden {
  display: none;
}
