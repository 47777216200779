@import "whitelabel/pepboys";

@import "application";

//Header Circle
.item.circle > .text {
  background-color: #3a3c3e !important;
  color: white !important;
  padding: 15px !important;
}

//Buttons Styles
button.link:hover,
button.singInLink:hover {
  color: #069 !important;
}

a:hover {
  color: unset !important;
}

.ui.button:hover {
  border: none !important;
}

.white-button:hover,
.ui.button.white-button:hover {
  background-color: $secondary-button-hover-color !important;
  border: none !important;
}

//Links
.ag-grid-link-column:hover {
  color: #0099ff !important;
}
.link:hover,
.maintenance-history-links:hover {
  color: #069 !important;
}
