.fleet_form_button--primary {
  margin-bottom: 20px;
  width: 100px;
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 6px;
  background: linear-gradient($button-gradient-start-color, $button-gradient-end-color);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.fleet_form_button--alternate {
  margin-bottom: 20px;
  width: 100px;
  font-size: 11px;
  font-weight: bold;
  color: #f8991d !important;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 6px;
  background: linear-gradient(#ffffff, #efefef);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.fleet_form_buttons {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .edit-buttons {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: start;
  }
}
