@media (max-width: 500px) {
  .approvals3_buttons_section {
    width: 100%;
  }
  .approvals3_form-summary-section {
    width: 100% !important;
  }
  .approvals3_form_header {
    flex-direction: column;
  }
  .approvals3_form_field_service__row {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
  .approvals3_form-review-section {
    width: 100% !important;
  }
  .approvals3_form_header {
    align-items: start;
    gap: 10px;
  }
  .site-header.menu {
    display: flex;
    flex-direction: column-reverse;
  }
  .approvals3_form_field__button_column {
    width: 100%;
    div {
      width: 100%;
    }
  }
}

.primary-text-color {
  color: $header-text-color !important;
}

.primary-background-color {
  background-color: $header-text-color !important;
}

.piggy-bank {
  padding: 0 15px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  align-self: stretch;
}
