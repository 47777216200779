.membership-card-menu {
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  background-color: #e2e9e5;

  .menu-logo {
    margin-top: 3px;
    path {
      stroke: $primary-color;
    }
  }

  .menu-label {
    color: $primary-color;
    font-weight: 500;
    font-size: 18px;
  }
}
