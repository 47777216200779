.customStyles {
  display: inline-flex;
  width: 385px;
  height: 410px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;

  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  border-radius: 4px;
  border: 1px solid rgba(241, 243, 249, 0.6);
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(196, 205, 228, 0.3);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.modelCheckboxContainer {
  display: flex;
  align-items: center;
  justify-items: center;
  cursor: pointer;
}

.modelCheckbox {
  float: left;
  display: flex;

  flex-direction: column;
}

.modelCheckbox h3 {
  margin: 0rem;
  padding: 0rem;
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: #282828;
}

.modelCheckbox .title-meta {
  color: #616161;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.modelCheckbox p {
  margin-left: 5px;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.modelCheckbox input {
  cursor: pointer;
}

.modal-custom-radio[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  cursor: pointer;
  /* For iOS < 15 */
  background-color: white;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  width: 20px;
  height: 20px;
  border: 1px solid $primary-color;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
  margin: 2px 10px 0px 0px;
}

.modelCheckboxContainer .recommended-label {
  margin: auto;
  background-color: $primary-color-light;
  color: $recommended-label-text-color;
}

.modal-custom-radio[type="radio"]::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  color: white;
  /* Windows High Contrast Mode */
  background-color: white;
}

input:not([type="range"]):not([type="color"]) {
  writing-mode: horizontal-tb !important;
}

.modal-custom-radio[type="radio"]:checked::before {
  transform: scale(1);
}

.modal-custom-radio[type="radio"]:checked {
  border: 10px solid $modal-custom-radio-checked-color;
}
