/* NOTE: The styles below have to work in conjection with the 'react-toggle-switch' libraries styling. See: 'react-toggle-switch/dist/css/switch.min.css' */

/* NOTE: The styles below will overwrite the libraries defaults */

/* NOTE: .switch pertains to the "track / background", .switch-toggle pertains to the "button / slider" */

/* NOTE: "Selected" switch buttons will be given a class of "on" */

.switch {
  height: 20px;
}

.switch .switch-toggle {
  height: 18px;
  width: 18px;
}

.switch.on {
  background: linear-gradient($button-gradient-start-color, $button-gradient-end-color);
}

.switch.on .switch-toggle {
  height: 18px;
  width: 18px;
  left: 30px;
}
