.geotab-container {
  width: 100%;
  .ui.grid > .row > [class*="center aligned"].column.column,
  .ui.grid > [class*="center aligned"].column.column,
  .ui.grid > [class*="center aligned"].row > .column,
  .ui[class*="center aligned"].grid > .column,
  .ui[class*="center aligned"].grid > .row > .column {
    height: 350px;
  }
  .modal-header {
    text-align: center !important;
  }
  .battery-info-section {
    width: 200px;
    margin-top: 1rem;
    text-align: center;
  }
  .engine-info-section {
    width: 200px;
    margin-top: 1rem;
    text-align: center;
  }
  .fault-code-section {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 100%;
    background-color: none;
  }
  .gauge-summary {
    display: block;
    // margin-top: 3px;
  }

  .gauge-summary-text {
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    margin: 5px 0px;
    text-align: center;
  }

  .battery-health-labels {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 2px 5px;
    border-radius: 25px;
    margin: 0 auto;
  }

  .tire-pressure-section {
    text-align: center;
    width: 350px;
    margin: auto;
  }
  .tire-pressure-image-section {
    text-align: center;
    width: 100%;
    .top-view-car {
      margin: auto;
    }

    .tire-pressure-left {
      display: block;
      float: left;

      .tire-pressure-top-left {
        position: absolute;
        top: 15px;
        left: 20%;
      }

      .tire-pressure-bottom-left {
        position: absolute;
        top: 75%;
        left: 20%;
      }
    }
    .tire-pressure-right {
      display: block;
      float: right;

      .tire-pressure-top-right {
        position: absolute;
        top: 15px;
        right: 20%;
      }
      .tire-pressure-bottom-right {
        position: absolute;
        top: 75%;
        right: 20%;
      }
    }
  }
  .tire-pressure-summary {
    display: grid;
    width: 60px;
    text-align: center;
    font-size: 20px;
    span {
      font-size: 14px;
    }
  }
  .tire-section-summary {
    display: flex;
    align-items: center;
  }
  .top-section {
    margin-top: 5px;
  }
  .bottom-section {
    margin-top: 30%;
  }
  .tire-pressure-left-section {
    position: absolute;
    font-size: 16px;
    left: 25px;
  }
  .tire-pressure-right-section {
    position: absolute;
    font-size: 16px;
    right: 25px;
  }
  .last-update-section {
    position: absolute;
    bottom: 0;
    -webkit-transform: translateX(50%);
  }
  .tire-pressure-last-update-section {
    position: absolute;
    bottom: 0;
    -webkit-transform: translateX(60%);
  }
  .fault-codes-last-update-section {
    position: absolute;
    bottom: 0;
    -webkit-transform: translateX(100%);
  }
}
.fault-codes-list {
  .ui.segment {
    position: relative;
    background: #ffe2e1;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 1em 2em;
    border-radius: 6px !important;
    border: 0px solid rgba(34, 36, 38, 0.15);
    margin: 10px;
  }
}
.fault-codes-header {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ffe2e1;
}
.fault-codes-item {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-weight: 600;
  color: #c10a0a;
}
.fault-codes-interval {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-weight: 400;
  text-transform: capitalize;
}
.fault-codes-content {
  display: block;
  width: 100%;
  height: auto;
  font-weight: 400;
  overflow: auto;
  color: #c10a0a;
}
.fault-codes-modal-button {
  .ui.modal .actions > .button {
    margin-left: 0em;
  }
}

@media only screen and (max-width: 1366px) {
  .geotab-container {
    .tire-pressure-section {
      text-align: center;
      width: 350px;
    }

    .tire-pressure-image-section {
      text-align: center;
      .top-view-car {
        margin: auto;
      }

      .tire-pressure-left {
        display: block;
        float: left;

        .tire-pressure-top-left {
          position: absolute;
          top: 15px;
          left: 20%;
        }

        .tire-pressure-bottom-left {
          position: absolute;
          top: 75%;
          left: 20%;
        }
      }
      .tire-pressure-right {
        display: block;
        float: right;

        .tire-pressure-top-right {
          position: absolute;
          top: 15px;
          right: 20%;
        }
        .tire-pressure-bottom-right {
          position: absolute;
          top: 75%;
          right: 20%;
        }
      }
    }
    .last-update-section {
      position: absolute;
      bottom: 20px;
      -webkit-transform: translateX(10%);
    }
    .tire-pressure-last-update-section {
      position: absolute;
      bottom: 20px;
      -webkit-transform: translateX(60%);
    }
    .fault-codes-last-update-section {
      position: absolute;
      bottom: 20px;
      -webkit-transform: translateX(50%);
    }
  }
}

@media only screen and (max-width: 1280px) {
  .geotab-container {
    .tire-pressure-section {
      text-align: center;
    }

    .tire-pressure-image-section {
      text-align: center;
      .top-view-car {
        margin: auto;
      }

      .tire-pressure-left {
        display: block;
        float: left;

        .tire-pressure-top-left {
          position: absolute;
          top: 15px;
          left: 20%;
        }

        .tire-pressure-bottom-left {
          position: absolute;
          top: 75%;
          left: 20%;
        }
      }
      .tire-pressure-right {
        display: block;
        float: right;

        .tire-pressure-top-right {
          position: absolute;
          top: 15px;
          right: 20%;
        }
        .tire-pressure-bottom-right {
          position: absolute;
          top: 75%;
          right: 20%;
        }
      }
      .last-update-section {
        position: absolute;
        bottom: 0;
        -webkit-transform: translateX(0);
      }
      .tire-pressure-last-update-section {
        position: absolute;
        bottom: 0;
        -webkit-transform: translateX(25%);
      }
    }
    .last-update-section {
      position: absolute;
      bottom: 20px;
      -webkit-transform: translateX(5%);
    }
    .tire-pressure-last-update-section {
      position: absolute;
      bottom: 20px;
      -webkit-transform: translateX(60%);
    }
    .fault-codes-last-update-section {
      position: absolute;
      bottom: 20px;
      -webkit-transform: translateX(40%);
    }
  }
}

@media only screen and (max-width: 992px) {
  .geotab-container {
    .last-update-section {
      position: absolute;
      bottom: 0;
      -webkit-transform: translateX(0%);
    }
    .tire-pressure-last-update-section {
      position: absolute;
      bottom: 20px;
      -webkit-transform: translateX(25%);
    }
    .fault-codes-last-update-section {
      position: absolute;
      bottom: 70px;
      -webkit-transform: translateX(15%);
    }
  }
}

@media only screen and (max-width: 840px) {
  .geotab-container {
    .last-update-section {
      position: absolute;
      bottom: 0;
      -webkit-transform: translateX(60%);
    }
    .tire-pressure-last-update-section {
      position: absolute;
      bottom: 0;
      -webkit-transform: translateX(60%);
    }
    .fault-codes-last-update-section {
      position: absolute;
      bottom: 0;
      -webkit-transform: translateX(60%);
    }
  }
}

@media only screen and (max-width: 767px) {
  .geotab-container {
    .ui.grid > .row > [class*="center aligned"].column.column,
    .ui.grid > [class*="center aligned"].column.column,
    .ui.grid > [class*="center aligned"].row > .column,
    .ui[class*="center aligned"].grid > .column,
    .ui[class*="center aligned"].grid > .row > .column {
      height: 500px;
    }
    .ui.celled.grid > .column:not(.row),
    .ui.celled.grid > .row > .column {
      height: 500px;
    }
    .last-update-section {
      position: absolute;
      bottom: 0;
      -webkit-transform: translateX(250px);
    }
    .tire-pressure-last-update-section {
      position: absolute;
      bottom: 0;
    }
    .fault-codes-last-update-section {
      position: absolute;
      bottom: 0;
      -webkit-transform: translateX(60%);
    }
  }
}

@media only screen and (max-width: 600px) {
  .geotab-container {
    .last-update-section {
      position: absolute;
      bottom: 70px;
      -webkit-transform: translateX(100%);
    }
    .tire-pressure-last-update-section {
      position: absolute;
      bottom: 100px;
      -webkit-transform: translateX(50%);
    }
    .fault-codes-last-update-section {
      position: absolute;
      bottom: 70px;
      -webkit-transform: translateX(60%);
    }
  }
}
@media only screen and (max-width: 500px) {
  .geotab-container {
    .last-update-section {
      position: absolute;
      bottom: 70px;
      -webkit-transform: translateX(95%);
    }
    .tire-pressure-last-update-section {
      position: absolute;
      bottom: 100px;
      -webkit-transform: translateX(60%);
    }
    .fault-codes-last-update-section {
      position: absolute;
      bottom: 70px;
      -webkit-transform: translateX(90%);
    }
  }
}
