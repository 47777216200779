.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  text-align: left;
}

.geosuggest__input-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style-type: none;
  white-space: nowrap;
}

.geosuggest__input {
  height: 60px;
  color: #999999;
  font-size: 18px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  padding-left: 0.75em;
}

.geosuggest__input:focus {
  border-color: #267dc0;
  box-shadow: 0 0 0 transparent;
}

.geosuggest__suggests {
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  margin-bottom: 0;
  background: #fff;
  border: 2px solid #267dc0;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
  margin: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}

.geosuggest__item__matched-text {
  font-weight: bold;
}
