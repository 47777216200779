@import "variables";

$ntw-primary: #005ca9;
$ntw-color: #fdba30;
$ntw-hovar-color: #024379;

$primary-color: $ntw-primary;
$primary-color-hover: $ntw-hovar-color;
$light-blue: $ntw-color;
$primary-button-color: $ntw-color;
$primary-button-hover-color: $ntw-hovar-color;
$header-text-color: $ntw-primary;
$primary-button-color: $ntw-color;
$primary-button-hover-color: $ntw-hovar-color;
$secondary-button-text-color: $ntw-color;
$recharts-primary-color: $ntw-color;
$recharts-secondary-color: $ntw-color;
$primary-color-light: #fef5cd;

/* ***** Glossary colors: ***** */
$ntw-yellow: #fdba30;
$ntw-yellow-hover: #cc9527;
$ntw-red: #dd1d21;
$ntw-red-hover: #a51518;
$ntw-dark-blue: #005ca9;
$ntw-dark-blue-hover: #024379;
$ntw-gray: #d1d2d4;
/* **************************** */

$secondary-color: $ntw-red;

$primary-button-color: $ntw-dark-blue;
$primary-button-hover-color: $ntw-color;

/* Sign in screen background gradient */
$primary-color-gradient-start: $ntw-gray;
$primary-color-gradient-end: $ntw-gray;

/* Recharts */
$recharts-primary-color: $ntw-primary;
$recharts-secondary-color: $ntw-primary;
/* $recharts-neutral-color: no override */
$recharts-negative-color: $ntw-red;

/* Sidebar icons */
$icon-color-active: $ntw-yellow;
$icon-color-inactive: white;

$font-family: "Montserrat", sans-serif !important;
$font-color: $ntw-gray;
$header-text-color: $ntw-primary;
$past-due-color: $ntw-red;
$due-soon-color: $ntw-dark-blue;

/* This is used in many places- not worth renaming the variable name for now */
$light-blue: $ntw-dark-blue;
$menu-hover-color: $light-blue;
$button-link-color: $primary-button-color;
$manufacturer-title-checkbox-color: $primary-color;
$orange-button-color: $primary-button-color;
$orange-button-color-hover: $primary-button-hover-color;
$recommended-label-text-color: $secondary-color;
$modal-custom-radio-checked-color: $primary-color;
$button-disabled-background-color: $primary-button-color;
$link-color: $primary-color;
