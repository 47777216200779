.user__button--primary {
  margin-bottom: 20px;
  width: 100px;
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 6px;
  background: -webkit-linear-gradient($button-gradient-start-color, $button-gradient-end-color);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.user__button--alternate {
  margin-bottom: 20px;
  width: 100px;
  font-size: 11px;
  font-weight: bold;
  color: #f8991d;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 6px;
  background: -webkit-linear-gradient(#ffffff, #efefef);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.user__buttons {
  flex: 1;
  text-align: end;
}

.user-email {
  flex: 2;
}

.user__form {
  flex: 9;
}

.user__form_container {
  color: #999999;
  display: flex;
  font-size: 14px;
  padding: 20px;
  margin-bottom: 30px;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  border: 1px solid #dddddd;
}

.user_form__notification_label_column {
  flex: 1.5;
}

.user_form__notification_fields_column {
  flex: 4.5;
}

.user-item {
  color: #999999;
}

.user-item label {
  display: inline-block;
  color: #999999;
  padding: 10px 20px;
  background-color: #efefef;
  border-radius: 6px;
}

.user-item select {
  width: 292px;
  height: 40px;
  color: #999999;
  font-size: 14px;
  background-color: #ffffff;
}

.user__label {
  font-weight: bold;
  margin-top: 8px;
  text-align: left;
  display: inline-block;
}

.user_maintenance_policies {
  padding: 20px;
  border: 1px solid #dddddd;
}

.user_maintenance_policies__add_button {
  font-size: 10px;
}

.user_maintenance_policies__no_results {
  font-size: 16px;
  padding: 20px;
}

.user_maintenance_policies__table_header {
  margin: 10px 0;
  color: #999999;
}

.user_maintenance_policy {
  margin-bottom: 15px;
}

.user_maintenance_policy:last-of-type {
  margin-bottom: 0px;
}

.user-name {
  flex: 2;
  font-size: 21px;
  font-weight: bold;
  color: #002d5e;
}

.user__row {
  margin-bottom: 15px;
}

.user-checkbox {
  label:after {
    background: $checkbox-background-color;
    color: $checkbox-checked-color !important;
    border-radius: 2px;
  }
}

.user-list-filter {
  display: flex;
  gap: 5px;
  align-items: center;
}

.user-list-container {
  height: 68vh;
  overflow: auto;

  .table-header {
    position: sticky !important;
    top: 0 !important;
    background-color: #fff;
    z-index: 9;
  }
}

.user-list-fleet-container-height {
  height: calc(100vh - 28vh) !important;
}

.user-driver-license {
  margin: 10px;
  text-align: -moz-center;

  .document-present-driver-license-icon {
    cursor: pointer;
  }

  .document-present-driver-license-icon-not-present {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
  }

  .span-margin-left {
    margin-left: 17px;
  }

  .other-doc-type-margin {
    margin: 10px;
  }
}
