.list_item__arrow {
  margin-top: 5px;
}

.list_item__container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 5px;
  background-color: #ffffff;
  align-items: center;
  border: 1px solid #dddddd;
  padding: 15px;
}

.list_item__container:hover {
  background-color: #f7f7f7;
}

.list_item__label {
  color: #999999;
  font-size: 12px;
  font-weight: normal;
}

.list_item__value {
  font-size: 18px;
  font-weight: bold;
  /* NOTE: Enforces height when value is null */
  min-height: 18px;
}
