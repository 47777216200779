.labeled_checkbox {
  box-sizing: border-box;
  color: #999999;
  display: inline-block;
  background-color: #efefef;
  border-radius: 6px;
  padding: 10px 20px;
  width: 100%;
}

.select-label {
  font-weight: 700;
}

.braintree-setting-modal {
  .__react_component_tooltip {
    left: -6% !important;
    top: 295px !important;
  }
}

.payment_ach__ref {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid silver;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;

  .payment_ach__title {
    color: #282828;
    font-weight: 500;
    font-size: 16px;
  }
}

.userlist_container {
  margin: 25px 0;
}
