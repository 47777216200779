.membership_parent {
  display: flex;
  align-items: center;
  justify-self: center;
  width: 100%;
  height: 100%;

  @media print {
    display: block !important;
    height: auto;
  }

  .membership_container {
    padding: 25px !important;
    margin: auto;
    width: 65rem;
    background: #fff;
    height: auto;
    display: flex;
    gap: 30px;
    align-items: stretch;
    border: 1px solid #d4d8de;
    border-radius: 4px;

    @media print {
      margin-top: 0;
      margin-left: auto !important;
      border: none !important;
      gap: 0;
      padding: 0;
    }

    .membership_cars {
      width: 55%;

      @media print {
        display: none !important;
      }
      .membership_box_title {
        color: #292f36;
        font-weight: 700;
        font-size: 23px;
        margin: 0 0 5px 0;
      }

      .membership_box_subheader {
        color: #586574;
        font-size: 16px;
        font-weight: 400;

        span {
          font-weight: 700;
          font-size: 16px;
        }
      }

      .membership_info {
        display: flex;
        align-items: start;
        gap: 10px;
        background-color: #292f3612;
        padding: 8px;
        border-radius: 4px;
        margin-bottom: 10px;
        .membership_help {
          color: #71727a;
          font-weight: 500;
          font-size: 14px;
        }
      }

      .fleet_no_vehicle_img {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 1rem 0 2rem 0;
      }

      .fleet_no_vehicle_text {
        color: #292f36;
        text-align: center;
        font-weight: 500;
        font-size: 19px;
      }

      .shimmer_squere {
        margin: 1px !important;
      }

      .shimmer_squere:first-child {
        margin-top: 10px !important;
      }

      .fleet_vehicles {
        margin: 15px 0;

        .fleet_vehicle_container {
          height: 30rem;
          overflow: auto;

          div {
            cursor: pointer;
          }
        }

        .bg_grey {
          background-color: #292f3612;
          border-radius: 4px;
        }
      }
    }

    .membership_download {
      background-color: $primary-color !important;
      border-radius: 4px !important;
      padding: 10px !important;
      width: 100%;
      margin-top: 5%;

      @media print {
        display: none;
      }

      .btn_label {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
        p {
          color: #fff !important;
        }
      }

      &:hover,
      &:focus {
        background-color: $primary-color !important;
      }
    }

    .membership_box {
      width: 45%;

      @media print {
        margin-left: 0 !important;
      }

      .shimmer_squere {
        margin-top: 20px;
      }

      .card_shimmer_squere {
        margin: 0;
      }

      .fleet_no_vehicle_box {
        display: flex;
        justify-content: end;

        .membership_logo {
          background-color: #e3eae6;
          border-radius: 0 0 0 6px;
          padding: 15px;
        }
      }

      .no_membership_info {
        color: #fff;
        font-size: 28px;
        font-weight: 400;
        top: 50%;
        position: absolute;
        text-align: center;
        width: 100%;
      }
    }

    .custom_bg {
      background-color: $primary-color;
      border-radius: 4px;
      position: relative;
    }
  }
}
