.row {
  margin-bottom: 15px;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.row:last-of-type {
  margin-bottom: 0px;
}

.row--aligned {
  align-items: center;
}

.row--bordered {
  padding: 20px;
  border: 1px solid #dddddd;
}

.row--full-width {
  width: 100%;
}

.row--header {
  display: flex;
  padding: 0 15px;
  margin-bottom: 5px;
  color: #999999;
}

.row--header:last-of-type {
  margin-bottom: 15px;
}

.row--overflow {
  flex: 0 0 auto;
}

.row--total {
  display: flex;
  background-color: #f7f7f7;
  justify-content: space-between;
  padding: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}

.column--1 {
  flex: 1;
  margin-right: 10px;
}

.column--1:last-of-type {
  margin-right: 0;
}

.column--2 {
  flex: 2;
  margin-right: 10px;
}

.column--2:last-of-type {
  margin-right: 0;
}

.column--3 {
  flex: 3;
  margin-right: 10px;
}

.column--3:last-of-type {
  margin-right: 0;
}

.column--4 {
  flex: 4;
  margin-right: 10px;
}

.column--4:last-of-type {
  margin-right: 0;
}

.column--5 {
  flex: 5;
  margin-right: 10px;
}

.column--5:last-of-type {
  margin-right: 0;
}

.column--6 {
  flex: 6;
  margin-right: 10px;
}

.column--6:last-of-type {
  margin-right: 0;
}

.column--7 {
  flex: 7;
  margin-right: 10px;
}

.column--7:last-of-type {
  margin-right: 0;
}

.column--8 {
  flex: 8;
  margin-right: 10px;
}

.column--8:last-of-type {
  margin-right: 0;
}

.column--9 {
  flex: 9;
  margin-right: 10px;
}

.column--9:last-of-type {
  margin-right: 0;
}

.column--10 {
  flex: 10;
  margin-right: 10px;
}

.column--10:last-of-type {
  margin-right: 0;
}

.column--11 {
  flex: 11;
  margin-right: 10px;
}

.column--11:last-of-type {
  margin-right: 0;
}

.column--12 {
  flex: 12;
  margin-right: 10px;
}

.column--12:last-of-type {
  margin-right: 0;
}
