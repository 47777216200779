@import "whitelabel/fleetadvise";

@import "application";

//Header Circle
.item.circle > .text {
  background-color: #fae0d9 !important;
  color: #292f36 !important;
  padding: 15px !important;
}

.circle-text-font {
  font-size: 22px;
  font-weight: 700 !important;
}

//Buttons Styles
button.link:hover,
button.singInLink:hover {
  color: $button-link-hover-color !important;
}

a:hover {
  color: unset !important;
}

.ui.button:hover {
  border: none !important;
}

.white-button:hover,
.ui.button.white-button:hover {
  background-color: $secondary-button-hover-color !important;
  color: #0099ff !important;
  border: none !important;
}

//Links
.ag-grid-link-column:hover {
  color: #21262b !important;
}

.link:hover,
.maintenance-history-links:hover {
  color: $button-link-hover-color !important;
}
