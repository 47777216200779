@import "whitelabel/discounttire";

@import "application";

//Header Circle
.item.circle > .text {
  background-color: $primary-color !important;
  color: $icon-color-inactive !important;
  padding: 15px !important;
}

//Buttons Styles
button.link:hover,
button.singInLink:hover,
a:hover {
  color: $dt-color !important;
}

.ui.button:hover {
  background-color: $primary-button-hover-color !important;
  color: #ffffff !important;
  border: 1px solid $primary-color !important;
}
.ui.button:focus {
  background-color: $primary-button-hover-color !important;
  color: #ffffff !important;
  outline: 2px solid $primary-color !important;
}

.white-button:hover,
.ui.button.white-button:hover {
  background-color: $secondary-button-hover-color !important;
  color: $primary-color !important;
  border: 1px solid $primary-color !important;
}

// Links
.ag-grid-link-column:hover {
  color: $dt-color !important;
  text-decoration: none;
}
.link:hover,
.maintenance-history-links:hover {
  color: $primary-button-hover-color !important;
}
