@import "variables";
$primary-color-gradient-start: #00a9d4;
$primary-color: #00a9d4;
$primary-color-light: #d5f1f8;
$primary-color-gradient-end: #00a9d4;
$primary-color-hover: #468cb7;
$secondary-color: $primary-color;
$manufacturer-title-checkbox-color: $primary-color;
$recommended-label-text-color: $secondary-color;
$modal-custom-radio-checked-color: $primary-color;
$button-disabled-background-color: #f8991d;
$link-color: $primary-color;
