.navbar {
  background-color: $primary-color !important;

  @media print {
    display: none !important;
  }

  .item.active,
  .item:hover {
    background-color: $primary-color-hover !important;
  }

  .item,
  .header {
    background-color: $primary-color !important;
  }
}

.ui.menu.site-header {
  @media print {
    display: none !important;
  }
  .item.fleet-label {
    color: $header-text-color !important;
  }

  .faq {
    color: $primary-color;
    cursor: pointer;
  }
}

.segment.menu-container > .ui.menu > .active.item {
  color: $light-blue !important;
  border-bottom: 3px solid $menu-hover-color;
}

.segment.menu-container > .ui.menu > .active.item:hover {
  color: $light-blue !important;
  border-bottom: 3px solid $menu-hover-color;
}

.segment.menu-container > .ui.menu > .item:hover {
  color: $light-blue !important;
  border-bottom: 3px solid $menu-hover-color;
}

.segment.menu-container > .ui.menu > .item {
  color: $gray !important;
  border-color: #e3e3e3;
}

.segment.menu-container > .ui.menu > .item.skinny-menu-button:hover {
  color: $gray;
  border-color: #e3e3e3;
}

.menu-theme-color {
  color: $primary-theme-text-color !important;
}

.active-navbar-tab {
  background-color: $active-navbar-tab-color;
  border-radius: 4px;
  padding: 4px;
}

.navbar-popup {
  background-color: $active-navbar-tab-color !important;
  color: $navbar-popup-text-color !important;
  border: unset;
}

.navbar-popup::before {
  background: $active-navbar-tab-color !important;
}

.border-unset {
  border-bottom: unset !important;
}

.font-weight {
  font-weight: 700 !important;
}
