.payment-row {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-bottom: 5px;
  background-color: #ffffff;
  align-items: center;
  border: 1px solid #dddddd;
}

.payment-column {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 15px;
  flex-direction: column;
  font-weight: bold;
}

.payment-column span {
  display: inline-block;
  color: #999999;
  font-size: 12px;
  font-weight: normal;
}

:root {
  --card-padding: 1.5em;
  --card-radius: 0.5em;
  --radio-border-width: 2px;
  --radio-size: 2em;
  --card-padding-approval-scren: 0.5em;
  --radio-size-approval-scren: 1em;
}

.payment-options {
  margin: 15px auto;

  p {
    color: black;
  }

  &.approval-screen {
    p {
      color: #666666;
      font-size: 14px;
    }
  }

  .payment_option__container {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.payment-options-content {
  position: relative;
  margin: 15px auto;
  min-height: 20vh;
  height: auto;

  .no-content {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    height: 20vh;
  }
}

.payment-grid {
  display: grid;
  grid-gap: var(--card-padding);
  margin: 0;
  max-width: 90em;
  padding: 0;

  &.approval-screen {
    max-width: 45em;
    grid-gap: var(--card-padding-approval-scren);
  }

  .payment_ach__container {
    position: relative;
    width: fit-content;
    .payment_ach {
      display: flex;
      align-items: center;
      gap: 20px;
      border: var(--radio-border-width) solid #efefef;
      padding: 20px;
      border-radius: var(--card-radius);
      cursor: pointer;
      transition: border-color 0.2s ease-out;

      &:hover {
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
        border: var(--radio-border-width) solid var(--color-dark-gray);
      }

      .payment_ach__header {
        color: #282828;
        font-weight: 500;
        font-size: 18px;
        font-family: "Catamaran", sans-serif !important;
        margin: 0;
      }

      .ach_verification {
        display: flex;
        align-items: center;
        gap: 5px;

        .ach_verification__label {
          color: #d97706;
          font-weight: 700;
          font-size: 16px;
          text-decoration: underline;
          margin: 0;
        }
      }
    }
  }

  .payment_ach__new {
    background-color: #e95832;
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
    color: #fff;
    width: fit-content;
    position: absolute;
    top: -10%;
    right: 5%;
  }

  @media (min-width: 42em) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.payment-card {
  background-color: #fff;
  border-radius: var(--card-radius);
  position: relative;

  &:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  }
}

.payment-radio {
  font-size: inherit;
  margin: 5px 0;
  position: absolute;
  left: calc(var(--card-padding) + var(--radio-border-width));
  top: calc(var(--card-padding) + var(--radio-border-width));

  &.approval-screen {
    left: calc(var(--card-padding-approval-scren) + var(--radio-border-width));
    top: calc(var(--card-padding-approval-scren) + var(--radio-border-width));
  }
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .payment-radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    border: var(--radio-border-width) solid $primary-color;
    border-radius: 50%;
    cursor: pointer;
    height: var(--radio-size);
    outline: none;
    transition: white 0.2s ease-out, border-color 0.2s ease-out;
    width: var(--radio-size);

    &.approval-screen {
      height: var(--radio-size-approval-scren);
      width: var(--radio-size-approval-scren);
    }

    &::after {
      border: var(--radio-border-width) solid $payment-radio-checked-color;
      border-top: 0;
      border-left: 0;
      content: "";
      display: block;
      height: 1rem;
      left: 25%;
      position: absolute;
      top: 50%;
      transform: rotate(45deg) translate(-50%, -50%);
      width: 0.375rem;
    }

    &.approval-screen::after {
      height: 0.5rem;
    }

    &:checked {
      background: $primary-button-color;
      border-color: $primary-button-color;
    }
  }

  .payment-radio-fleetadvise:checked ~ .payment-details {
    background-color: $primary-theme-radio-selected-color !important;
    border-color: $primary-theme-radio-border-color !important;
  }

  .payment-radio-fleetadvise {
    &:checked {
      background: $dashboard-link-color !important;
    }
  }

  .payment-card:hover .radio {
    border-color: var(--color-dark-gray);

    &:checked {
      border-color: $primary-button-color;
    }
  }
}

.payment-details {
  border: var(--radio-border-width) solid $light-gray;
  border-radius: var(--card-radius);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: var(--card-padding);
  transition: border-color 0.2s ease-out;
  justify-content: center;
  flex-direction: row;

  &.approval-screen {
    padding: 2px;
  }
}

.payment-card:hover .payment-details {
  border-color: var(--color-dark-gray);
}

.payment-radio:checked ~ .payment-details {
  background-color: rgba($primary-button-color, 0.1);
  border-color: $primary-button-color;
}

.payment-radio:focus ~ .payment-details {
  box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.payment-radio:disabled ~ .payment-details {
  color: var(--color-dark-gray);
  cursor: default;
}

.payment-radio:disabled ~ .payment-details .payment-type {
  color: var(--color-dark-gray);
}

.payment-card:hover .payment-radio:disabled ~ .payment-details {
  border-color: $light-gray;
  box-shadow: none;
}

.payment-card:hover .payment-radio:disabled {
  border-color: $light-gray;
}

.payment-type {
  color: $primary-button-color;
  font-size: 18px;
  font-weight: bold;
  line-height: 1em;
  align-self: center;
  margin: 5px;

  &.approval-screen {
    font-size: 12px;
  }
}

.payment-color {
  color: $primary-theme-text-color !important;
}

.payment-details-section {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: #e2e5e9;
  background-color: #fff;
  margin-bottom: 10px;

  .radio-icon {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: #a7b2be;
  }
}
.awaiting-aproval.payment-details-section:hover,
.selected-payment-section {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: $primary-color-light;
  background: $primary-color-light;
  margin-bottom: 10px;

  .radio-icon {
    display: flex;
    width: 16px;
    height: 16px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: $primary-color !important;
    background: $primary-color !important;
  }
}
.payment-method-label-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1 0 0;
  justify-content: space-between;

  .payment-details-label {
    align-self: stretch;
    color: #3c4550;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
  }

  .fleetcor-wex-text {
    margin: auto 0 auto 0;
  }

  .payment-details-value {
    color: #3c4550;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}
.payment-icon {
  display: flex;
  width: 47px;
  height: 34px;
  padding: 5.03px 5.642px 5.57px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: #f0f2f4;
  background: #fff;
}

.scrollable-div {
  height: 150px;
  overflow-y: auto;
  width: 100%;
}

/* For WebKit-based browsers (Chrome, Safari) */
.scrollable-div::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
}

.scrollable-div::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
}
