.fleet_summary__container {
  display: flex;
  margin: 0 30px 15px 30px;
  justify-content: center;
}

.fleet_summary__label {
  color: #00295f;
  font-size: 12px;
  font-weight: normal;
}

.fleet_summary__tile {
  flex: 1;
  text-align: center;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fleet_summary__value {
  color: #00295f;
  font-size: 20px;
  font-weight: bold;
}

.fleet-highlight {
  background-color: $fleet-list-highlight-color;
  width: 53px;
  height: 25px;
  border-radius: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  border: 1px solid $fleet-list-highlight-border-color;
  color: $primary-theme-text-color;
  padding: 4px;
  gap: 10px;
}

.fleet-list-highlight {
  color: $fleet-list-highlight-text-color;
  background-color: $fleet-list-highlight-color;
}
