.service-daterange {
  display: flex;
  flex-direction: row;

  .service-datepicker {
    display: inline-flex;
    vertical-align: middle;
    line-height: 2.5em;
    margin-right: 1em;

    input {
      padding: 5px;
      margin-left: 0.5em;
      font-size: larger;
    }
  }

  .date-filter-label {
    margin: 0;
    font-weight: 500;
    align-self: center;
    margin-right: 4px !important;
    cursor: none !important;
  }

  .download-button {
    margin-left: 14px;
  }
}

.right-section-option {
  padding-right: 0 !important;
}

.size-to-fit {
  label::after {
    background: $checkbox-background-color;
    border-radius: 3px;
    border: 1.5px solid $checkbox-background-color !important;
  }

  input:checked ~ label:after {
    color: $checkbox-checked-color !important;
    border: 1px solid $checkbox-border-color !important;
    font-size: 12px;
  }

  input:focus:checked ~ label:after {
    color: $checkbox-checked-color !important;
    border: 1px solid $checkbox-border-color;
  }
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: $transfer-vehicle-checkbox-background-color !important;
}

.ui.checkbox label:before {
  border: 1.5px solid $checkbox-border-color !important;
}

.checkbox-color {
  color: $transfer-vehicle-checkbox-background-color;
}

.approvals3_form_field-service-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;

  .plus_icon {
    width: 16px;
    height: 16px;
  }

  .custom_service {
    display: flex;
    padding: 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 0px;
  }

  .custom_service_name {
    color: $link-color !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-decoration-line: underline;
  }
}

.custom-modal.ui.modal > .close {
  right: -4.5rem !important;
}

.ui.modal.custom-modal > .content,
.add_model_section {
  display: flex;
  width: 400px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 12px;
  background: #fff;

  .header_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }

  .header_section_label {
    align-self: stretch;
    color: #101828;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
  }

  .header_section_value {
    align-self: stretch;
    color: #101828;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .input_field_section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;

    .input_field {
      display: flex;
      height: 42px;
      padding: 10px 14px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }

  .buttons_section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    .button_section {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      flex: 1 0 0;
      border-radius: 8px;
      background: #d1e0d8;

      .button_cover {
        display: flex;
        align-items: flex-start;
        flex: 1 0 0;
      }

      .button {
        display: flex;
        padding: 10px 18px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        border-radius: 8px;
        background: #d1e0d8;
      }

      .button_text {
        color: #21262c;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 111.111% */
      }
    }

    .enabled_button_section {
      display: flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 0px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 8px;
      background: #292f36;

      .button_cover {
        display: flex;
        padding: 0px 4px;
        align-items: flex-start;
        gap: 0px;
      }

      .button_text {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 111.111% */
      }
    }

    .enabled_delete_button_section {
      display: flex;
      align-items: flex-start;
      flex: 1 0 0;
      border-radius: 8px;

      .button_cover {
        display: flex;
        padding: 10px 18px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1px solid #e02d3c;
        background: #e02d3c;
        box-shadow: 0px 1px 2px 0px #e02d3c;
      }

      .button_text {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 111.111% */
      }
    }

    .delete_icon_section {
      display: flex;
      width: 48px;
      height: 48px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      background: #fef1f2;
    }
  }

  .disabled_save_button_section {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 0px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    background: #c4ccd4;

    .disable_button_cover {
      display: flex;
      padding: 0px 4px;
      align-items: flex-start;
      gap: 0px;
    }

    .save_text {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 114.286% */
    }
  }

  .header_sub_text {
    color: #475467;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}

.vanity_edit_section {
  display: flex;
  align-items: center;
  gap: 4px;

  .show_exist_vanity_name {
    display: flex;
    padding: 2px 6px 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: #f0f2f4;

    .text {
      color: #3c4550;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 114.286% */
    }

    .cross_icon {
      width: 12px;
      height: 12px;
    }
  }

  .edit_link_section {
    display: flex;
    padding: 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 0px;

    .edit_text {
      color: $link-color !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      text-decoration-line: underline;
    }
  }
}

.hand-icon {
  cursor: pointer;
}

.disabled-icon {
  cursor: not-allowed;
}

.scrollable-container {
  width: 30vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 10px;
  display: flow-root;
}
