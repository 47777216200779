.modal-dialog {
  font-family: $font-family;
}

.modal-dialog .modal-dialog-header {
  background: $primary-color;
  .modal-dialog-header-title {
    color: $primary-button-text-color;
  }
}
