.arrow-container {
  text-align: right;
  padding-right: 10px;
}

.arrow-gray {
  width: 14px;
  align-items: right;
}

.icon-alert--recalls {
  width: 20px;
  align-items: right;
}

.icon-alert {
  width: 30px;
  align-items: right;
}

.logo-container {
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #1a426e;
}

.logo {
  margin-top: 32px;
  align-items: center;
  justify-content: center;
}

.nav__logo {
  margin-top: 7px;
  align-items: center;
  justify-content: center;
}

.stars {
  width: 125px;
}
