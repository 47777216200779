@mixin text-input {
  height: 50px;
  width: 360px;
  color: #999999;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 15px;
}

.placeholder-color-change {
  input::-webkit-input-placeholder {
    color: #595959 !important;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #595959 !important;
  }

  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #595959 !important;
  }

  input:-ms-input-placeholder {
    color: #595959 !important;
  }
}

.promo-input-width {
  width: 360px !important;
}

.sign-in-form {
  .divider {
    font-size: initial;
  }
}

.search-input-parent {
  display: flex;

  .search-input-box {
    width: 21%;
    margin-right: 10px;

    @media screen and (max-width: 425px) {
      width: 50%;
    }
  }

  .search-input-box-vin {
    width: 100%;
    margin-right: 10px;

    @media screen and (max-width: 425px) {
      width: 50%;
    }
  }

  .filter-options-parent {
    position: absolute;
    width: 33%;
    z-index: 1;
    border: 1px solid $input-search-border-color;
    margin-top: 5px;
    background: #fff;
    padding: 15px;
    border-radius: 5px;

    @media screen and (max-width: 425px) {
      width: 53%;
    }
  }

  .vin-filter {
    width: 84% !important;
  }

  .filter-options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    input {
      margin-right: 5px;
      accent-color: $primary-color;
    }
  }
}

.search-fleet {
  width: 100%;
}

.radio-btn-color {
  accent-color: $checkbox-background-color !important;
}

.search-margin {
  margin-left: 10px !important;
}
