.sfo__tracking {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: $primary-color;
  border-radius: 4px;
  gap: 15px;

  .sfo-tracking-label {
    display: flex;
    align-items: center;
    gap: 15px;
    .sfo__tracking-label,
    .sfo__tracking-step {
      color: #f3f3f3;
      font-family: "Catamaran", sans-serif;
      font-style: normal;
      text-align: left;
    }

    .sfo__tracking-label {
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      margin: 0;
      cursor: pointer;
      text-decoration: underline;
    }

    .sfo__tracking-step {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }
  }

  .sfo-action-label {
    color: #f3f3f3;
    font-family: "Catamaran", sans-serif;
    font-weight: 600;
    cursor: pointer;
    margin: 0;
  }
}
