.no-session-container {
  // font-family: Arial;
}

.ui.input > input:focus {
  border-color: #115d91 !important;
}
.firstname-and-lastName {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 105%;
}
@media screen and (max-width: 325px) {
  .firstname-and-lastName {
    display: flex;
    flex-direction: column;
    max-width: 0;
  }
}
.disabled-button-shell {
  opacity: 0.8;
  filter: alpha(opacity=80);
  cursor: not-allowed !important;
  pointer-events: none;
}

.field-labels {
  align-self: flex-start;
  font-weight: bold;
  margin-bottom: 6px;
  margin-top: 5px;
}
@media screen and (max-width: 768px) {
  .field-labels {
    margin-bottom: -3px;
  }
}
@media screen and (max-width: 325px) {
  .last-name-label {
    display: none;
  }
}
@media screen and (min-width: 325px) {
  .last-name-label-forsmalldevices {
    display: none;
  }
}
.firstname-lastname-labels {
  display: flex;
  width: 100%;
}

.already-have-customer {
  display: block;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 15px;
  margin-right: 275px;
}

.signup-fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .padding-0 {
    padding: 0px;
  }
}
@media screen and (max-width: 325px) {
  .signup-fields {
    width: 55%;
  }
}
@media screen and (min-width: 326px) and (max-width: 768px) {
  .signup-fields {
    width: 40%;
  }
}

.signup-text {
  margin-bottom: 20px;
}
.t-and-c-container {
  width: 150px;
  display: flex;
  flex-direction: column;
}
.t-and-c-text {
  font-size: 11px;
}
.checkbox-signupbutton {
  min-width: 100%;
}
.language-french {
  width: 150px;
  display: flex;
  flex-direction: column;
  margin-left: 3px;
}
.language-french-text {
  font-size: 11px;
}
.language {
  min-width: 15%;
  margin-right: 158px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  text-align: left;
  margin-bottom: 5px;
}
.terms-and-conditions {
  display: flex;
  margin-bottom: 15px;
  align-items: baseline;
  text-align: left;
}
