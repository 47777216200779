.ach_verification {
  padding: 25px;

  .ach_verification__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;

    .verification_subheader {
      color: #282828;
      font-weight: 700;
      font-size: 20px;
      margin: 0;
    }

    .closeLogo {
      cursor: pointer;
    }
  }

  .ach_verification__note {
    color: #616161;
    font-weight: 400;
    font-size: 16px;
  }

  .verification_success {
    display: flex;
    align-items: center;
    gap: 15px;
    border: 1px solid #cfeadd;
    background: #f0f8f4;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 10px 12px;

    .verification_success__note {
      margin: 0;
      color: #282828;
      font-weight: 700;
      font-size: 16px;
    }
  }

  .verification_expires {
    display: flex;
    gap: 9px;
    align-items: start;
    border: 1px solid #fecaca;
    border-radius: 4px;
    background: #fef2f2;
    padding: 8px;
    margin-bottom: 12px;

    .verificationLogo {
      margin-top: 3px;
    }

    .verification_expired__header {
      margin: 0;
      color: #282828;
      font-weight: 700;
      font-size: 16px;
    }

    .verification_expired__note {
      margin: 5px 0 0 0;
      color: #616161;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .verification_failed {
    display: flex;
    align-items: start;
    gap: 15px;
    border-radius: 4px;
    padding: 8px;
    border: 1px solid #fecaca;
    background: #fef2f2;
    margin-bottom: 15px;

    .verificationLogo {
      margin-top: 5px;
    }

    .ach_failed__title {
      margin: 0;
      color: #282828;
      font-weight: 700;
      font-size: 16px;
    }

    .ach_failed__note {
      margin: 0;
      color: #616161;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .ach_verification__form {
    margin-bottom: 15px;
    .ach_verification__label {
      color: #2f3036;
      font-weight: 700;
      font-size: 14px;
      margin: 0;
    }
    .ach_verification__input {
      width: 100%;
      input {
        padding: 12px 16px;
        border-radius: 4px !important;
      }
    }
    .ach_verification__error {
      width: 100%;
      padding: 10px;
      color: red;
    }
  }
  .ach_verification__button {
    width: 100%;
    background-color: $primary-color !important;
    color: #fff !important;
    padding: 16px 20px !important;
    border-radius: 4px;
    margin-top: 15px;

    &:hover {
      background-color: $primary-color !important;
      color: #fff !important;
    }
  }

  .ach_loader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    .loader {
      width: 50px;
      aspect-ratio: 1;
      display: grid;
      border-radius: 50%;
      background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5) 30%,
            rgba(0, 0, 0, 0) 0 70%,
            rgba(0, 0, 0, 1) 0
          )
          50%/8% 100%,
        linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.25) 30%,
            rgba(0, 0, 0, 0) 0 70%,
            rgba(0, 0, 0, 0.75) 0
          )
          50%/100% 8%;
      background-repeat: no-repeat;
      animation: l23 1s infinite steps(12);

      &::before,
      &::after {
        content: "";
        grid-area: 1/1;
        border-radius: 50%;
        background: inherit;
        opacity: 0.915;
        transform: rotate(30deg);
      }

      &::after {
        opacity: 0.83;
        transform: rotate(60deg);
      }

      @keyframes l23 {
        100% {
          transform: rotate(1turn);
        }
      }
    }

    .please_hold {
      color: #3c3c3c;
      font-weight: 700;
      margin: 30px 0 20px 0;
      font-size: 16px;
    }
  }
}
