.password_field {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 60px;
  width: 100%;
  color: #999999;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 10px;
}

.password_field--small {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 45px;
  width: 100%;
  color: #999999;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
}
