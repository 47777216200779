.manual-maintenance-history-container {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 4px;
  border: 1px solid #d4d8de;
  background: #fff;

  .mmh-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .mmh-header {
      color: #292f36;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .mmh-vehicle-info-section,
  .mmh-vehicle-service-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    .mmh-header {
      color: $button-link-color;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .mmh-vehicle-content,
    .mmh-service-content {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;

      .mmh-vehicle-info-fields {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
      }
    }
    .mmh-service-section {
      background: #fafafa;
      border-radius: 2px;
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
    }
  }

  .horizontal-divider {
    align-self: stretch;
    border-top: 1px solid #e0e0e0;
  }

  .history-accordion {
    border-radius: 4px;
    margin-bottom: 10px;
    width: 100%;
  }

  .mmh-add-more-button,
  .mmh-remove-button {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 2px;
    background: #fafafa;
    color: $button-link-color;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    text-decoration-line: underline;
    cursor: pointer;

    .mmh-add-more-button-icon {
      padding: 5px 0px 0px 1px;
    }
  }

  .mmh-remove-button {
    justify-content: end;
    padding: 0;

    .injected-svg {
      height: 14;
      width: 15;
      path {
        fill: $button-link-color;
      }
    }
  }
  // .mmh-add-more-button:hover,
  // .mmh-add-more-button:active,
  // .mmh-add-more-button:focus {
  //   background: $primary-color;
  // }

  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }

  .history-label {
    color: #616161;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; /* 114.286% */
    display: flex;
  }

  .history-amount-section {
    display: flex;
    align-items: center;
    gap: 8px;
    .history-amount {
      display: flex;
      padding: 2px 4px;
      align-items: center;
      gap: 4px;
      border-radius: 22px;
      background: #3c3c3c;
      color: #e4e4e4;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .accordion-content {
    padding: 10px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    .mmh-vehicle-info-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;

      .mmh-vehicle-content {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;

        .mmh-vehicle-info-fields {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          flex: 1 0 0;

          .service-notes-field {
            width: 100% !important;
          }
        }
      }
    }
  }

  .mmh-save-button-container {
    display: flex;
    padding: 24px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    .mmh-save-button {
      display: flex;
      width: 521px;
      height: 48px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: $primary-color;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      cursor: pointer;

      .injected-svg {
        path {
          stroke: #fff;
        }
      }
    }
  }

  .disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .error-message {
    border: red 1px solid !important;
    border-radius: 7px;
  }

  .ui.left.icon.input.disabled-field input[readonly] {
    background-color: #f0f0f0; /* Override the background color */
    color: black; /* Override the text color */
    opacity: 1; /* Override the opacity */
    cursor: text; /* Override the cursor style */
  }

  .angle-arrow {
    font-size: 30px;
    vertical-align: middle;
    /* left: 5px; */
    margin-left: 8px;
  }
  .mini-loader {
    width: 20px;
    height: 20px;
    display: grid;
    border-radius: 50%;
    mask: radial-gradient(farthest-side, #0000 40%, #9cadc4 41%);
    -webkit-mask: radial-gradient(farthest-side, #0000 40%, #9cadc4 41%);
    background: linear-gradient(0deg, rgba(156, 173, 196, 0.5) 50%, rgba(156, 173, 196, 1) 0)
        center/1.9px 100%,
      linear-gradient(90deg, rgba(156, 173, 196, 0.25) 50%, rgba(156, 173, 196, 0.75) 0) center/100%
        1.9px;
    background-repeat: no-repeat;
    animation: mini-loader-d3o0rx 1s infinite steps(12);
  }

  .mini-loader::before,
  .mini-loader::after {
    content: "";
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
  }

  .mini-loader::after {
    opacity: 0.83;
    transform: rotate(60deg);
  }

  @keyframes mini-loader-d3o0rx {
    100% {
      transform: rotate(1turn);
    }
  }
}
