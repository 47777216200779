.tooltip {
  display: inline;
  margin: 0 5px;
  font-weight: normal;
}

.tooltip__icon {
  color: #999999;
  background: #efefef;
  display: inline-block;
  margin: 0;
  padding: 2px 6px;
  border-radius: 10px;
}

.tooltip__row {
  margin-bottom: 5px;
}

.tooltip__row:last-of-type {
  margin-bottom: 0;
}
