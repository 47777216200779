.active-tab {
  background-color: $primary-color-hover;
}

.nav {
  display: flex;
  position: fixed;
  width: 100px;
  height: 100vh;
  background-color: $primary-color;
  text-align: center;
  flex-direction: column;
}

.nav-container {
  flex-direction: column;
}

.nav-item {
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #1a426e;
}

.nav-item:hover {
  background-color: $primary-color-hover;
}

.nav a {
  text-decoration: none;
}

.nav a span {
  color: #ffffff;
  font-size: 9px;
}

.navbar .borderless-outline .fleetcor {
  background-color: white !important;
  height: 83px;
}

.item:hover .icon-hover {
  color: #da291c;
}

.navbar.fleetcor {
  max-width: 200px !important;
}
